import { MODIFIER_PATHS } from './paths'

export interface ModifierTab {
  name: string
  href: string
  current: boolean
  selectable: boolean
  title: string
  explanation?: string
  nextLink?: string | undefined
  prevLink?: string | undefined
  headline?: string
}

export const MODIFIER_SURVEY_TABS: ModifierTab[] = [
  {
    name: 'intro.tab',
    href: '',
    current: true,
    selectable: true,
    title: 'intro.question',
  },
  {
    name: 'revenue.tab',
    href: MODIFIER_PATHS.REVENUE,
    current: false,
    selectable: true,
    title: 'revenue.question',
    explanation: 'revenue.explanation',
  },
  {
    name: 'fuel.tab',
    href: MODIFIER_PATHS.FUEL,
    current: false,
    selectable: false,
    title: 'fuel.question',
    explanation: 'fuel.explanation',
    headline: 'fuel.headline'
  },
  {
    name: 'heating.tab',
    href: MODIFIER_PATHS.HEATING,
    current: false,
    selectable: false,
    title: 'heating.question',
    explanation: 'heating.explanation',
  },
  {
    name: 'renewableEnergyShare.tab',
    href: MODIFIER_PATHS.ELECTRICITY,
    current: false,
    selectable: false,
    title: 'renewableEnergyShare.question',
    explanation: 'renewableEnergyShare.explanation',
  },
  {
    name: 'rent.tab',
    href: MODIFIER_PATHS.RENT,
    current: false,
    selectable: false,
    title: 'rent.question',
    explanation: 'rent.explanation',
  },
  {
    name: 'dietKind.tab',
    href: MODIFIER_PATHS.FOOD,
    current: false,
    selectable: false,
    title: 'dietKind.question',
    explanation: 'dietKind.explanation',
    headline: 'dietKind.headline'
  },
  {
    name: 'organicProducts.tab',
    href: MODIFIER_PATHS.BIO,
    current: false,
    selectable: false,
    title: 'organicProducts.question',
  },
  {
    name: 'officeImprovement.tab',
    href: MODIFIER_PATHS.FURNITURE,
    current: false,
    selectable: false,
    title: 'officeImprovement.question',
  },
  {
    name: 'electronics.tab',
    href: MODIFIER_PATHS.ELECTRONICS,
    current: false,
    selectable: false,
    title: 'electronics.question',
  },
  {
    name: 'hotels.tab',
    href: MODIFIER_PATHS.HOTELS,
    current: false,
    selectable: false,
    title: 'hotels.question',
  },
  {
    name: 'consumption.tab',
    href: MODIFIER_PATHS.CONSUMPTION,
    current: false,
    selectable: false,
    title: 'consumption.question',
  },
  {
    name: 'summary.tab',
    href: MODIFIER_PATHS.RESULTS,
    current: false,
    selectable: false,
    title: 'summary.question',
    explanation: 'summary.explanation',
  },
]
