import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useYearStore } from '../../store/useYearStore'

export const YearSwitch = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setYearParams] = useSearchParams()
  const { t } = useTranslation()

  const setYear = useYearStore((state) => state.setYear)
  const year = useYearStore((state) => state.year)

  const _curYear = new Date().getFullYear()
  const years = [
    _curYear + 2,
    _curYear + 1,
    _curYear,
    _curYear - 1,
    _curYear - 2,
  ]

  const onYearSwitch = (e: ChangeEvent<HTMLSelectElement>) => {
    if (!e.target.value) {
      return
    }

    const year = Number.parseInt(e.target.value)
    setYear(year)
    setYearParams({ year: year.toString() })
  }

  return (
    <div className="pb-8 pl-2 pr-8">
      <label htmlFor="year" className="block text-sm font-medium text-gray-700">
        {t('common.showDataFor')}
      </label>
      <select
        id="year"
        name="year"
        className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-brand focus:outline-none focus:ring-brand sm:text-sm"
        value={year}
        onChange={onYearSwitch}
      >
        {years.map((year) => (
          <option value={year} key={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  )
}
