import { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { updateCustomer } from '../api/customer/updateCustomer'
import { getUser } from '../api/user/getUser'
import { LoadingSpinner } from '../components/core/LoadingSpinner'
import { PageShell } from '../components/core/PageShell'
import { ProfileForm, ProfileType } from '../components/core/ProfileForm'
import { AccountingSoftware } from '../models/accountingSoftware.enum'
import { Customer } from '../models/customer'
import { Industry } from '../models/industry.enum'
import { User } from '../models/user'
import { useCustomerStore } from '../store/useCustomerStore'
import { useUserStore } from '../store/useUserStore'

export const ProfilePage = () => {
  const [profileData, setProfileData] = useState<any>(undefined)

  const user = useUserStore((state) => state.user)
  const setUser = useUserStore((state) => state.setUser)
  const customer = useCustomerStore((state) => state.customer)
  const isFake = useCustomerStore((state) => state.isFake)
  const setCustomer = useCustomerStore((state) => state.setCustomer)

  useEffect(() => {
    setProfileData({
      firstName: isFake ? '' : user?.firstName || '',
      lastName: isFake ? '' : user?.lastName || '',
      companyName: customer?.companyName || '',
      industry:
        customer?.industry && customer.industry in Industry
          ? customer.industry
          : Industry.OTHER,
      industryOther:
        customer?.industry && !(customer.industry in Industry)
          ? customer.industry
          : undefined,
      accountingSoftware:
        customer?.accountingSoftware &&
        customer.accountingSoftware in AccountingSoftware
          ? customer.accountingSoftware
          : AccountingSoftware.OTHER,
      accountingSoftwareOther:
        customer?.accountingSoftware &&
        !(customer.accountingSoftware in AccountingSoftware)
          ? customer.accountingSoftware
          : undefined,
    })
  }, [customer, user, isFake])

  const handleFormSubmit = async (profileData: ProfileType) => {
    if (customer?.id && profileData) {
      updateCustomer(customer.id, {
        firstName: profileData.firstName,
        lastName: profileData.lastName,
        companyName: profileData.companyName,
        country: profileData.country,
        industry:
          profileData.industry === Industry.OTHER
            ? profileData.industryOther
            : profileData.industry,
        accountingSoftware:
          profileData.accountingSoftware === AccountingSoftware.OTHER
            ? profileData.accountingSoftwareOther
            : profileData.accountingSoftware,
      }).then((customerResp: AxiosResponse<Customer>) => {
        if (user) {
          getUser(user.id).then((userResp: AxiosResponse<User>) => {
            setCustomer(customerResp.data)
            setUser(userResp.data)
          })
        }
      })
    }
  }

  if (!customer || !user) {
    return <LoadingSpinner />
  }

  return (
    <PageShell title="Profil">
      <div className="bg-white px-4 py-4 rounded-md max-w-4xl">
        <h3 className="text-lg pb-4 font-bold">Profil</h3>
        <ProfileForm
          onSubmit={handleFormSubmit}
          profileData={profileData}
          enableUserChanges={!isFake}
        />
      </div>
    </PageShell>
  )
}
