export enum PATHS {
  REGISTRATION = '/registration',
  LOGIN = '/login',
  LOGOUT = '/logout',
  DASHBOARD = '/dashboard',
  TRANSACTIONS = '/transactions',
  MODIFIER = '/modifiers',
  REPORTS = '/reports',
  MEASURES = '/measures',
  SUPPLIERS = '/suppliers',
  ONBOARDING = '/onboarding',
  TERMS = '/agb',
  TERMS_EN = '/terms',
  ADMIN = '/admin',
  PROFILE = '/profile',
}

export enum MODIFIER_PATHS {
  INTRO = '/intro',
  REVENUE = '/revenue',
  FUEL = '/fuel',
  HEATING = '/heating',
  ELECTRICITY = '/electricity',
  RENT = '/rent',
  FOOD = '/food',
  BIO = '/bio',
  FURNITURE = '/furniture',
  ELECTRONICS = '/electronics',
  HOTELS = '/hotels',
  CONSUMPTION = '/consumptions',
  RESULTS = '/results',
}

export enum ADMIN_PATHS {
  CUSTOMER_LOGIN = '/customer-login',
  EMPLOYEE_COSTS = '/employee-costs',
  EMISSION_MAPPING = '/emission-mapping',
}

export enum DASHBOARD_PATHS {
  LIVE = '/live',
  FINAL = '/final',
}
