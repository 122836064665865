import { Dialog, Transition } from '@headlessui/react'
import { AxiosError, AxiosResponse } from 'axios'
import { ChangeEvent, Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '../core/Button'

interface JSONUploadFormType {
  jsonDataInput: string
  jsonDataFile: string
}

interface JSONUploadComponentProps {
  uploadData: (data: any) => Promise<AxiosResponse<any, any>>
  onUploadSuccess: (data: any) => void
}

export const JSONUploadComponent = ({
  uploadData,
  onUploadSuccess,
}: JSONUploadComponentProps) => {
  const [open, setOpen] = useState(false)
  const [uploadSuccess, setUploadSuccess] = useState(false)
  const { register, formState, getValues, setError, watch, setValue } =
    useForm<JSONUploadFormType>({
      mode: 'onChange',
    })

  const watchFile = watch('jsonDataFile')
  const watchInput = watch('jsonDataInput')

  const parseAndUploadData = (data: string) => {
    let parsedEmissionMapping = undefined
    try {
      parsedEmissionMapping = JSON.parse(data)
    } catch (e: unknown) {
      if (e instanceof SyntaxError) {
        setError('jsonDataInput', {
          message: JSON.stringify(e.message),
        })
      }
      return
    }

    uploadData(parsedEmissionMapping)
      .then((resp) => {
        onUploadSuccess(resp.data)
        setUploadSuccess(true)
        setValue('jsonDataFile', '')
        setValue('jsonDataInput', '')
        setOpen(false)
      })
      .catch((e: AxiosError) => {
        setError('jsonDataInput', {
          message:
            JSON.stringify(e.message) + '\n' + JSON.stringify(e.response?.data),
        })
      })
  }

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const fileReader = new FileReader()

    if (!e?.target?.files || !e.target.files[0]) {
      setError('jsonDataFile', {
        message: 'Fehler beim Upload der Datei',
      })
      return
    }

    fileReader.readAsText(e.target.files[0], 'UTF-8')
    fileReader.onload = (e) => {
      if (!e.target?.result) {
        setError('jsonDataFile', {
          message: 'Fehler beim Upload der Datei',
        })
        return
      }

      const result: string =
        typeof e.target.result === 'string'
          ? e.target.result
          : Buffer.from(e.target.result).toString()

      setValue('jsonDataFile', result)
    }
  }

  return (
    <>
      {uploadSuccess ? (
        <p className="bg-brand-light px-2 py-0 bg-opacity-50 rounded-md ">
          Daten erfolgreich aktualisiert!
        </p>
      ) : (
        <Button
          label={'Daten aktualisieren'}
          onClick={() => setOpen(true)}
          variant="primary"
        />
      )}

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div className="pt-4 w-full">
                    <Dialog.Title
                      as="h3"
                      className="text-2xl font-medium leading-6 text-gray-900 text-center"
                    >
                      Daten aktualisieren
                    </Dialog.Title>

                    <div className="py-8 ">
                      <p className="text-gray-700 py-2 text-center">
                        Datei hochladen:
                      </p>
                      <input
                        type="file"
                        onChange={handleFileUpload}
                        className="pt-2"
                      />
                      {formState.errors.jsonDataFile && (
                        <p className="text-red-600 bg-red-100 rounded-md px-4 py-1 text-sm mt-1 whitespace-pre-line">
                          {formState.errors.jsonDataFile.message}
                        </p>
                      )}
                    </div>
                    <p className="text-gray-700 py-2 text-center">
                      oder selbst eingeben:
                    </p>
                    <textarea
                      rows={2}
                      {...register('jsonDataInput')}
                      id="emissionMapping"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand focus:ring-brand sm:text-sm"
                    />
                    {formState.errors.jsonDataInput && (
                      <p className="text-red-600 bg-red-100 rounded-md px-4 py-1 text-sm mt-1 whitespace-pre">
                        {formState.errors.jsonDataInput.message}
                      </p>
                    )}
                    <div className="pt-4 w-full flex justify-end">
                      <Button
                        label="Hochladen"
                        variant="primary"
                        disabled={!watchFile && !watchInput}
                        onClick={() =>
                          parseAndUploadData(
                            getValues('jsonDataInput')
                              ? getValues('jsonDataInput')
                              : getValues('jsonDataFile')
                          )
                        }
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}
