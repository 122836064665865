import { AgGridReact } from 'ag-grid-react'
import { useEffect, useState } from 'react'
import { getEmployeeCost } from '../../api/employee-cost/getEmployeeCost'
import { updateEmployeeCost } from '../../api/employee-cost/updateEmployeeCost'
import { EmployeeCost } from '../../models/employeeCost'
import { useYearStore } from '../../store/useYearStore'
import { JSONUploadComponent } from './JSONUpload'

export const EmployeeCostTable = () => {
  const [rowData, setRowData] = useState<any[]>()
  const [employeeCost, setEmployeeCost] = useState<EmployeeCost[]>([])
  const [displayedCost, setDisplayedCosts] = useState<EmployeeCost[]>([])
  const year = useYearStore((state) => state.year)

  const defaultColDef = {
    sortable: true,
    editable: false,
    resizable: true,
  }
  const columnDefs = [
    {
      field: 'year',
      headerName: 'Jahr',
      sort: 'desc' as any,
    },
    {
      field: 'country',
      headerName: 'Land',
      filter: 'agTextColumnFilter',
      sort: 'desc' as any,
    },
    {
      field: 'businessCategory',
      headerName: 'Business Category',
      filter: 'agTextColumnFilter',
      sort: 'desc' as any,
    },
    {
      field: 'amount',
      headerName: 'Wert',
      filter: 'agNumberColumnFilter',
    },
  ]

  useEffect(() => {
    const fetchMapping = async () => {
      const results = await getEmployeeCost()

      if (results.status === 200 && results.data) {
        setEmployeeCost(results.data)
      }
    }

    fetchMapping()
  }, [])

  useEffect(() => {
    setDisplayedCosts(employeeCost.filter((cost) => cost.year === year))
  }, [year, employeeCost])

  useEffect(() => {
    setRowData(displayedCost)
  }, [displayedCost])

  return (
    <div className="h-[75vh]">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            Grundwerte für Ausgaben
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Enthält für alle Biz Kategorien die Ausgaben (Euro) pro Mitarbeiter,
            pro Jahr und pro Land
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <JSONUploadComponent
            uploadData={updateEmployeeCost}
            onUploadSuccess={(newCost) => setEmployeeCost(newCost)}
          />
        </div>
      </div>

      <div
        className="ag-theme-alpine mt-4 bg-white shadow-sms rounded-md py-4 px-4 h-full"
        style={{ width: '100%', height: '100%' }}
      >
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          onGridReady={(e) => {
            e.api.sizeColumnsToFit({ defaultMinWidth: 200 })
          }}
        />
      </div>
    </div>
  )
}
