import {
  ArrowLeftOnRectangleIcon,
  BanknotesIcon,
  CalculatorIcon,
  ChartBarIcon,
  ExclamationTriangleIcon,
  FolderIcon,
  HomeIcon,
  ShieldExclamationIcon,
  UserIcon,
} from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import { useCustomerStore } from '../../store/useCustomerStore'
import { useUserStore } from '../../store/useUserStore'
import { PATHS } from '../../utils/enums/paths'
import { LanguageSwitch } from './LanguageSwitch'
import { LinkWithQuery } from './LinkWithQuery'
import { LoadingSpinner } from './LoadingSpinner'
import { YearSwitch } from './YearSwitch'

const navigation = [
  {
    name: 'common.navLink.home',
    href: PATHS.DASHBOARD,
    icon: HomeIcon,
    admin: false,
  },
  {
    name: 'common.navLink.transactions',
    href: PATHS.TRANSACTIONS,
    icon: BanknotesIcon,
    admin: false,
  },
  {
    name: 'common.navLink.modifiers',
    href: PATHS.MODIFIER,
    icon: CalculatorIcon,
    admin: false,
  },
  {
    name: 'common.navLink.reports',
    href: PATHS.REPORTS,
    icon: ChartBarIcon,
    admin: false,
    disabled: true,
  },
  {
    name: 'common.navLink.suppliers',
    href: PATHS.SUPPLIERS,
    icon: FolderIcon,
    admin: false,
    disabled: true,
  },
  {
    name: 'common.navLink.measures',
    href: PATHS.MEASURES,
    icon: ShieldExclamationIcon,
    admin: false,
    disabled: true,
  },
  {
    name: 'common.navLink.admin',
    href: PATHS.ADMIN,
    icon: ExclamationTriangleIcon,
    admin: true,
  },
]

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

export const Sidebar = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const customer = useCustomerStore().customer
  const isFake = useCustomerStore().isFake
  const user = useUserStore().user

  if (customer === undefined || user === undefined) {
    return <LoadingSpinner></LoadingSpinner>
  }

  return (
    <>
      <div className="inset-y-0 flex w-64 flex-col">
        <div
          className={classNames(
            'flex flex-grow flex-col overflow-y-auto border-r border-gray-200 pt-5',
            isFake ? 'bg-red-500 bg-opacity-20' : 'bg-white'
          )}
        >
          {isFake ? (
            <div className="bg-red-300 rounded-md px-1 py-1 flex flex-col mx-2">
              <span>Eingeloggt als: </span>
              <span className="font-bold">{customer.companyName}</span>
              <span>
                Vorsicht, Änderungen können Kundendaten überschreiben!
              </span>
              <a href="0" className="underline">
                Fake-Login zurücksetzen
              </a>
            </div>
          ) : (
            <div className="flex flex-shrink-0 items-center px-4 relative h-24 justify-center flex-col">
              <img
                src="/logos/ecoincome_logo.svg"
                alt="eco.income logo"
                className="p-4"
              />
            </div>
          )}

          <hr className="mt-4" />

          <div className="mt-5 flex flex-grow flex-col">
            <nav className="flex-1 space-y-1 px-2 pb-4">
              {navigation
                .filter((item) => {
                  if (!user.admin) {
                    return !item.admin
                  }

                  return item
                })
                .map((item) => {
                  if (item.disabled) {
                    return (
                      <>
                        <Tooltip
                          anchorId={`nav-${item.href}`}
                          place="right"
                          className="w-60"
                        />

                        <div
                          id={`nav-${item.href}`}
                          data-tooltip-content={t(
                            'common.availableSoon'
                          ).toString()}
                          className={classNames(
                            'text-gray-300',
                            'group rounded-md py-2 px-2 flex items-center text-sm font-medium'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              'text-gray-300',
                              'mr-3 flex-shrink-0 h-6 w-6'
                            )}
                            aria-hidden="true"
                          />
                          {t(item.name)}
                        </div>
                      </>
                    )
                  }

                  return (
                    <LinkWithQuery
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        location.pathname.includes(item.href)
                          ? 'bg-gray-100 text-gray-900'
                          : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                        'group rounded-md py-2 px-2 flex items-center text-sm font-medium'
                      )}
                    >
                      <item.icon
                        className={classNames(
                          location.pathname.includes(item.href)
                            ? 'text-gray-500'
                            : 'text-gray-400 group-hover:text-gray-500',
                          'mr-3 flex-shrink-0 h-6 w-6'
                        )}
                        aria-hidden="true"
                      />

                      {t(item.name)}
                    </LinkWithQuery>
                  )
                })}
            </nav>
          </div>

          <div>
            <LinkWithQuery
              to={PATHS.PROFILE}
              className={classNames(
                location.pathname.includes(PATHS.PROFILE)
                  ? 'bg-gray-100 text-gray-900'
                  : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                'group rounded-md py-2 px-2 flex items-center text-sm font-medium'
              )}
            >
              <UserIcon
                className={classNames(
                  location.pathname.includes(PATHS.PROFILE)
                    ? 'text-gray-500'
                    : 'text-gray-400 group-hover:text-gray-500',
                  'mr-3 flex-shrink-0 h-6 w-6'
                )}
                aria-hidden="true"
              />
              {t('common.navLink.profile')}
            </LinkWithQuery>
            <LinkWithQuery
              to={PATHS.LOGOUT}
              className="text-gray-600 pb-4 hover:bg-gray-50 hover:text-gray-900 group rounded-md py-2 px-2 flex items-center text-sm font-medium"
            >
              <ArrowLeftOnRectangleIcon
                className="text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6"
                aria-hidden="true"
              />
              Logout
            </LinkWithQuery>
            <div className="pl-2 border-t pt-4">
              <div className="pl-2 pr-8 pb-4">
                <LanguageSwitch />
              </div>

              <YearSwitch />
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  )
}
