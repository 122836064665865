import create from 'zustand'
import { devtools } from 'zustand/middleware'
import { Customer } from '../models/customer'

interface CustomerStore {
  customer: Customer | undefined
  isFake: boolean
  customerId: string
  setCustomer: (newCustomer: Customer) => void
  resetCustomer: () => void
  setFakeCustomer: (fakeCustomer: Customer) => void
}
export const useCustomerStore = create<CustomerStore>(
  devtools((set) => ({
    customer: undefined,
    setCustomer: (newCustomer: Customer) =>
      set(() => ({ customer: newCustomer, customerId: newCustomer.id })),
    resetCustomer: () => set(() => ({ customer: undefined })),
    setFakeCustomer: (fakeCustomer: Customer) =>
      set(() => ({ customer: fakeCustomer, isFake: true })),
  })) as any
)
