import { Dialog, Transition } from '@headlessui/react'
import { BuildingOfficeIcon } from '@heroicons/react/24/outline'
import { AxiosResponse } from 'axios'
import { Fragment, Suspense, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { updateCustomer } from '../api/customer/updateCustomer'
import { getUser } from '../api/user/getUser'
import { LoadingSpinner } from '../components/core/LoadingSpinner'
import { ProfileForm, ProfileType } from '../components/core/ProfileForm'
import { AccountingSoftware } from '../models/accountingSoftware.enum'
import { Customer } from '../models/customer'
import { Industry } from '../models/industry.enum'
import { User } from '../models/user'
import { useCustomerStore } from '../store/useCustomerStore'
import { useUserStore } from '../store/useUserStore'
import { PATHS } from '../utils/enums/paths'

export const OnboardingPage = () => {
  const [profileData, setProfileData] = useState<any>(undefined)

  const cancelButtonRef = useRef(null)
  const user = useUserStore((state) => state.user)
  const setUser = useUserStore((state) => state.setUser)
  const customer = useCustomerStore((state) => state.customer)
  const setCustomer = useCustomerStore((state) => state.setCustomer)
  const navigate = useNavigate()
  const { t } = useTranslation()

  useEffect(() => {
    setProfileData({
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      companyName: customer?.companyName || '',
      industry:
        customer?.industry && customer.industry in Industry
          ? customer.industry
          : Industry.OTHER,
      industryOther:
        customer?.industry && !(customer.industry in Industry)
          ? customer.industry
          : undefined,
      accountingSoftware:
        customer?.accountingSoftware &&
        customer.accountingSoftware in AccountingSoftware
          ? customer.accountingSoftware
          : AccountingSoftware.OTHER,
      accountingSoftwareOther:
        customer?.accountingSoftware &&
        !(customer.accountingSoftware in AccountingSoftware)
          ? customer.accountingSoftware
          : undefined,
    })
  }, [customer, user])

  useEffect(() => {
    if (
      user?.firstName &&
      user.lastName &&
      customer?.companyName &&
      customer.country &&
      customer.industry &&
      customer.accountingSoftware
    ) {
      navigate(`${PATHS.DASHBOARD}?year=${new Date().getFullYear() - 1}`)
    }
  }, [customer, navigate, user])

  const handleFormSubmit = async (profileData: ProfileType) => {
    if (customer?.id && profileData) {
      updateCustomer(customer.id, {
        firstName: profileData.firstName,
        lastName: profileData.lastName,
        companyName: profileData.companyName,
        country: profileData.country,
        industry:
          profileData.industry === Industry.OTHER
            ? profileData.industryOther
            : profileData.industry,
        accountingSoftware:
          profileData.accountingSoftware === AccountingSoftware.OTHER
            ? profileData.accountingSoftwareOther
            : profileData.accountingSoftware,
      }).then((customerResp: AxiosResponse<Customer>) => {
        if (user) {
          getUser(user.id).then((userResp: AxiosResponse<User>) => {
            setCustomer(customerResp.data)
            setUser(userResp.data)

            navigate(PATHS.DASHBOARD)
          })
        }
      })
    }
  }

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => undefined}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100">
                      <BuildingOfficeIcon
                        className="h-8 w-8 text-brand"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left flex-1">
                      <Dialog.Title
                        as="h3"
                        className="text-xl font-medium leading-6 text-gray-900"
                      >
                        {t('onboarding.title')}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-md text-gray-500 pb-8">
                          {t('onboarding.subtitle')}
                        </p>
                        <Suspense fallback={<LoadingSpinner />}>
                          <ProfileForm
                            onSubmit={handleFormSubmit}
                            profileData={profileData}
                            enableUserChanges={true}
                          />
                        </Suspense>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
