import { sendEmailVerification } from 'firebase/auth'
import { useEffect, useRef, useState } from 'react'
import { useCreateUserWithEmailAndPassword } from 'react-firebase-hooks/auth'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { LanguageSwitch } from '../components/core/LanguageSwitch'
import { LinkWithQuery } from '../components/core/LinkWithQuery'
import { LoadingSpinner } from '../components/core/LoadingSpinner'
import { auth } from '../firebase.config'
import { PATHS } from '../utils/enums/paths'

interface SignupType {
  email: string
  password: string
  passwordConfirm: string
  terms: boolean
}

export const RegistrationPage = () => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const { register, handleSubmit, formState, watch } = useForm<SignupType>({
    mode: 'onBlur',
  })
  const [verificationEmailSend, setVerificationEmailSend] = useState(false)
  const password = useRef({})
  password.current = watch('password', '')

  const [createUserWithEmailAndPassword, user, loading, error] =
    useCreateUserWithEmailAndPassword(auth)

  useEffect(() => {
    if (user) {
      if (!user.user.emailVerified) {
        sendEmailVerification(user.user)
        setVerificationEmailSend(true)
      } else {
        navigate(PATHS.DASHBOARD)
      }
    }
  }, [user, navigate])

  if (error) {
    return (
      <div>
        <p>Error: {error.message}</p>
      </div>
    )
  }

  if (loading) {
    return <LoadingSpinner />
  }

  const onSubmit = async (data: SignupType) => {
    try {
      await createUserWithEmailAndPassword(data.email, data.password)
    } catch (error: any) {
      console.error(error.message)
    }
  }

  return (
    <>
      <div className="flex min-h-full w-full bg-white">
        <div className="flex flex-col h-full justify-between">
          <div className="flex flex-1 h-full flex-col justify-center py-12 lg:flex-none overflow-hidden">
            <div className="mx-auto max-w-md lg:max-w-xl w-[50vw] overflow-y-auto px-4 sm:px-6 lg:px-20 xl:px-24 py-8">
              <div className="flex justify-center items-center">
                <img
                  className="h-24 2xl:h-40 w-auto"
                  src="/logos/ecoincome_logo.svg"
                  alt="eco.income logo"
                />
              </div>
              {verificationEmailSend ? (
                <div className="mt-8">
                  <h2 className="my-6 relative text-3xl font-bold tracking-tight text-gray-900 text-center">
                    {t('login.registration.verificationTitle')}
                  </h2>

                  <span className="text-gray-500 text-center w-full block">
                    {t('login.registration.verificationText')}
                  </span>

                  <LinkWithQuery to="/login">
                    <button
                      type="submit"
                      className="mt-6 flex w-full justify-center rounded-md border border-transparent bg-brand py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-brand focus:outline-none focus:ring-2 focus:ring-brand focus:ring-offset-2"
                    >
                      {t('login.registration.loginBtn')}
                    </button>
                  </LinkWithQuery>
                </div>
              ) : (
                <>
                  <h2 className="mt-6 relative text-3xl font-bold tracking-tight text-gray-900 text-center">
                    {t('login.registration.title')}
                  </h2>
                  <div className="mt-8">
                    <div className="mt-6">
                      <form
                        className="space-y-6"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <LanguageSwitch />

                        {/* E-Mail Input */}
                        <div>
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {t('login.registration.form.email')}
                          </label>
                          <div className="mt-1">
                            <input
                              id="email"
                              {...register('email', {
                                required: t(
                                  'login.registration.form.emailHint'
                                )!,
                                pattern: {
                                  value: /\S+@\S+\.\S+/,
                                  message: t(
                                    'login.registration.form.emailHint'
                                  ),
                                },
                              })}
                              type="email"
                              autoComplete="email"
                              required
                              className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-brand focus:outline-none focus:ring-brand sm:text-sm"
                            />
                            {formState.errors.email && (
                              <p className="text-red-600 bg-red-100 rounded-md px-4 py-1 text-sm mt-1">
                                {formState.errors.email.message}
                              </p>
                            )}
                          </div>
                        </div>

                        {/** Password input */}
                        <div className="space-y-1">
                          <label
                            htmlFor="password"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {t('login.registration.form.password')}
                          </label>
                          <div className="mt-1">
                            <input
                              id="password"
                              {...register('password', {
                                required: t(
                                  'login.registration.form.passwordHint'
                                )!,
                                minLength: {
                                  value: 9,
                                  message: t(
                                    'login.registration.form.passwordHint'
                                  )!,
                                },
                                pattern: {
                                  value:
                                    // eslint-disable-next-line no-useless-escape
                                    /^(?=.*[A-Za-z])((?=.*\d)|(?=.*[@$!%*#?&\_\-:,;.'+=()/"§^°\[\]{}]))[A-Za-z\d@$!%*#?&\_\-:,;.'+=()/"§^°\[\]{}]{8,}$/,
                                  message: t(
                                    'login.registration.form.passwordHint'
                                  ),
                                },
                              })}
                              type="password"
                              required
                              className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-brand focus:outline-none focus:ring-brand sm:text-sm"
                            />
                            {formState.errors.password && (
                              <p className="text-red-600 bg-red-100 rounded-md px-4 py-1 text-sm mt-1">
                                {formState.errors.password.message}
                              </p>
                            )}
                          </div>
                        </div>

                        {/** Password repeat input */}
                        <div className="space-y-1">
                          <label
                            htmlFor="password-repeat"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {t('login.registration.form.passwordRepeat')}
                          </label>
                          <div className="mt-1">
                            <input
                              id="password-repeat"
                              {...register('passwordConfirm', {
                                required: t(
                                  'login.registration.form.passwordRepeatHint'
                                )!,
                                validate: (value) =>
                                  value === password.current ||
                                  t(
                                    'login.registration.form.passwordRepeatHint'
                                  )!,
                              })}
                              type="password"
                              required
                              className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-brand focus:outline-none focus:ring-brand sm:text-sm"
                            />
                            {formState.errors.passwordConfirm && (
                              <p className="text-red-600 bg-red-100 rounded-md px-4 py-1 text-sm mt-1">
                                {formState.errors.passwordConfirm.message}
                              </p>
                            )}
                          </div>
                        </div>

                        {/** Terms input */}
                        <div className="flex items-center">
                          <input
                            id="terms"
                            {...register('terms', {
                              required: t('login.registration.form.termsHint')!,
                            })}
                            required
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-brand focus:ring-brand"
                          />
                          <label
                            htmlFor="terms"
                            className="ml-2 block text-sm text-gray-900"
                          >
                            {t('login.registration.form.terms')}{' '}
                            <Link
                              to={
                                i18n.languages.includes('de')
                                  ? PATHS.TERMS
                                  : PATHS.TERMS_EN
                              }
                              className="underline hover:text-brand"
                              target="_blank"
                            >
                              {t('login.registration.form.termsLink')}
                            </Link>
                          </label>
                        </div>

                        <button
                          type="submit"
                          disabled={!formState.isValid}
                          className="flex w-full justify-center rounded-md border disabled:bg-gray-300 border-transparent bg-brand py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-brand focus:outline-none focus:ring-2 focus:ring-brand focus:ring-offset-2"
                        >
                          {t('login.registration.form.button')}
                        </button>
                      </form>
                    </div>
                    <div className="relative mt-6">
                      <div
                        className="absolute inset-0 flex items-center"
                        aria-hidden="true"
                      >
                        <div className="w-full border-t border-gray-300" />
                      </div>
                      <div className="relative flex justify-center text-sm">
                        <span className="bg-white px-2 text-gray-500">
                          {t('login.registration.hint')}
                        </span>
                      </div>
                    </div>

                    <div>
                      <Link to="/login">
                        <button
                          type="submit"
                          className="mt-6 flex w-full justify-center rounded-md border border-transparent bg-gray-100 py-2 px-4 text-sm font-medium text-black shadow-sm hover:bg-brand-light focus:outline-none focus:ring-2 focus:ring-brand-light focus:ring-offset-2"
                        >
                          {t('login.registration.loginBtn')}
                        </button>
                      </Link>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="/img/registration_bg.jpeg"
            alt=""
          />
        </div>
      </div>
    </>
  )
}
