import { User as FirebaseUser } from 'firebase/auth'
import { useEffect } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useLocation, useSearchParams } from 'react-router-dom'
import { getCustomer } from './api/customer/getCustomer'
import { getHealth } from './api/status/getHealth'
import { getUser } from './api/user/getUser'
import { LinkWithQuery } from './components/core/LinkWithQuery'
import { LoadingSpinner } from './components/core/LoadingSpinner'
import { RouteConfig } from './components/core/RouteConfig'
import { auth } from './firebase.config'
import { Customer } from './models/customer'
import { User } from './models/user'
import { useCustomerStore } from './store/useCustomerStore'
import { useUserStore } from './store/useUserStore'
import { useYearStore } from './store/useYearStore'
import { PATHS } from './utils/enums/paths'
import { initAxios } from './utils/initAxios'

import 'react-tooltip/dist/react-tooltip.css'

function App() {
  const [user, loading, error] = useAuthState(auth)
  const [yearParams, setYearParams] = useSearchParams()
  const setCustomer = useCustomerStore((state) => state.setCustomer)
  const location = useLocation()

  const setUser = useUserStore((state) => state.setUser)
  const dbUser = useUserStore().user
  const storeYear = useYearStore((state) => state.year)
  const setYear = useYearStore((state) => state.setYear)

  useEffect(() => {
    if (storeYear !== undefined) {
      return
    }

    const year = yearParams.get('year')
    if (year && !Number.isNaN(year)) {
      setYear(Number.parseInt(year))
    } else if (
      ![
        PATHS.LOGIN.toString(),
        PATHS.LOGOUT.toString(),
        PATHS.TERMS.toString(),
        PATHS.REGISTRATION.toString(),
      ].includes(location.pathname)
    ) {
      setYearParams({ year: (new Date().getFullYear() - 1).toString() })
    }
  }, [setYear, setYearParams, yearParams, storeYear, location])

  useEffect(() => {
    const fetchCustomer = async (user: FirebaseUser) => {
      const token = await user.getIdToken(true)
      initAxios(token)

      const userData = await getUser(user.uid)
      setUser(userData.data as User)

      const customerId = userData.data.customerId

      const customer = await getCustomer(customerId)
      setCustomer(customer.data as Customer)
    }

    if (user && user.emailVerified) {
      fetchCustomer(user)
    }
  }, [setCustomer, user, setUser])

  useEffect(() => {
    initAxios()
    getHealth()
  }, [])

  if (loading) {
    return <LoadingSpinner></LoadingSpinner>
  }

  if (error) {
    return (
      <div className="w-full h-full flex items-center justify-center bg-black flex-col">
        <div className="text-white ">Fehler beim Login</div>
        <LinkWithQuery to={PATHS.LOGIN} className="text-white pt-8 underline">
          Erneut versuchen
        </LinkWithQuery>
      </div>
    )
  }

  return (
    <div className="h-full flex">
      <RouteConfig user={dbUser} />
    </div>
  )
}

export default App
