import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { ColDef } from 'ag-grid-community'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { Fragment, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCSVReader } from 'react-papaparse'
import { PATHS } from '../../utils/enums/paths'

export interface UploadTransactionsModalProps {
  isOpen: boolean
  afterFileUpload: (rowData: any[], colDefs: ColDef[]) => void
  onClose: () => void
  hasAlreadyAcceptedHint: boolean
}

export const UploadTransactionsModal = ({
  isOpen,
  afterFileUpload,
  onClose,
  hasAlreadyAcceptedHint,
}: UploadTransactionsModalProps) => {
  const { t, i18n } = useTranslation(['transactions', 'common'])
  const { CSVReader } = useCSVReader()

  const [open, setOpen] = useState(isOpen)
  const [acceptedHint, setAcceptedHint] = useState(hasAlreadyAcceptedHint)

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  const cancelButtonRef = useRef(null)

  const handleNextButtonClick = () => {
    if (!acceptedHint) {
      setAcceptedHint(true)
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto ">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full max-w-xl">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 w-full h-full">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon
                        className="h-6 w-6 text-yellow-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        {acceptedHint
                          ? t('uploadModal.titleUpload')
                          : t('uploadModal.titlePayment')}
                      </Dialog.Title>

                      <div className="mt-2">
                        {acceptedHint ? (
                          <div>
                            <CSVReader
                              onUploadAccepted={(results: any) => {
                                const data = results?.data
                                const headers = data.shift()

                                const columnDefs =
                                  headers.map((name: string) => ({
                                    field: name,
                                  })) || []

                                const rowData = data
                                  .filter(
                                    (rowData: any[]) =>
                                      !(
                                        rowData.length === 1 &&
                                        rowData[0] === ''
                                      )
                                  )
                                  .map((rowData: any[]) => {
                                    const row = {}

                                    Object.assign(
                                      row,
                                      ...headers.map(
                                        (name: string, i: number) => ({
                                          [name]: rowData[i],
                                        })
                                      )
                                    )

                                    return row
                                  })

                                afterFileUpload(rowData, columnDefs)
                                onClose()
                              }}
                            >
                              {({
                                getRootProps,
                                acceptedFile,
                                ProgressBar,
                              }: any) => (
                                <>
                                  <div {...getRootProps()}>
                                    {acceptedFile ? (
                                      <>
                                        <div>
                                          <div>
                                            <span>Upload erfolgreich: </span>
                                            <span>{acceptedFile.name}</span>
                                          </div>

                                          <div>
                                            <ProgressBar />
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <div className="py-8">
                                        <button
                                          type="button"
                                          className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-brand focus:ring-offset-2"
                                        >
                                          <svg
                                            className="mx-auto h-12 w-12 text-gray-400"
                                            xmlns="http://www.w3.org/2000/svg"
                                            stroke="currentColor"
                                            fill="none"
                                            viewBox="0 0 48 48"
                                            aria-hidden="true"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth={2}
                                              d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
                                            />
                                          </svg>
                                          <span className="mt-2 block text-sm font-medium text-gray-900">
                                            {t('uploadModal.uploadHint')}
                                          </span>
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </>
                              )}
                            </CSVReader>
                          </div>
                        ) : (
                          <span className="text-sm text-gray-500">
                            {t('uploadModal.paymentPart1')}
                            <a
                              href={
                                i18n.language.includes('de')
                                  ? PATHS.TERMS
                                  : PATHS.TERMS_EN
                              }
                              className="underline"
                              target="_blank"
                              rel="noreferrer"
                            >
                              {t('uploadModal.paymentLinkText')}
                            </a>{' '}
                            {t('uploadModal.paymentPart2')}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  {!acceptedHint && (
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-brand px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-brand-dark focus:outline-none focus:ring-2 focus:ring-brand focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={handleNextButtonClick}
                    >
                      {t('uploadModal.continueBtn')}
                    </button>
                  )}
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={onClose}
                    ref={cancelButtonRef}
                  >
                    {t('uploadModal.cancelBtn')}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
