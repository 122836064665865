export enum DietKind {
  MEAT_HIGH = 'MEAT_HIGH',
  MEAT_MED = 'MEAT_MED',
  MEAT_LOW = 'MEAT_LOW',
  FISH = 'FISH',
  VEGETARIAN = 'VEGETARIAN',
  VEGAN = 'VEGAN',
}

export enum Electronics {
  SUSTAINABLE_HIGH = 'SUSTAINABLE_HIGH',
  SUSTAINABLE_MED = 'SUSTAINABLE_MED',
  SUSTAINABLE_LOW = 'SUSTAINABLE_LOW',
}

export enum GasAndFuel {
  PETROL = 'PETROL',
  CNG = 'CNG',
  CNG_BIO = 'CNG_BIO',
  ELECTRICITY_GRIDMIX = 'ELECTRICITY_GRIDMIX',
  ELECTRICITY_GREEN = 'ELECTRICITY_GREEN',
}

export enum HeatingKind {
  COAL = 'COAL',
  DISTRICT = 'DISTRICT',
  ELECTRICITY_GRIDMIX = 'ELECTRICITY_GRIDMIX',
  ELECTRICITY_GREEN = 'ELECTRICITY_GREEN',
  GAS = 'GAS',
  GAS_BIO = 'GAS_BIO',
  LPG = 'LPG',
  OIL = 'OIL',
  PELLETS = 'PELLETS',
}

export enum Hotels {
  SUSTAINABLE_HIGH = 'SUSTAINABLE_HIGH',
  SUSTAINABLE_MED = 'SUSTAINABLE_MED',
  SUSTAINABLE_LOW = 'SUSTAINABLE_LOW',
}

export enum OfficeImprovement {
  SUSTAINABLE_HIGH = 'SUSTAINABLE_HIGH',
  SUSTAINABLE_MED = 'SUSTAINABLE_MED',
  SUSTAINABLE_LOW = 'SUSTAINABLE_LOW',
}

export enum OrganicProducts {
  ORGANIC_FULL = 'ORGANIC_FULL',
  ORGANIC_MED = 'ORGANIC_MED',
  ORGANIC_LOW = 'ORGANIC_LOW',
}

export enum RenewableEnergyShare {
  RENEWABLE_FULL = 'RENEWABLE_FULL',
  RENEWABLE_GRIDMIX = 'RENEWABLE_GRIDMIX',
}
