import axios from 'axios'
import { AccountingData } from '../../models/accountingData'
import { API_ROUTES } from '../routes'

export const updateAccountingData = (
  customerId: string,
  accountingData: AccountingData[]
) => {
  return axios.post(
    API_ROUTES.CUSTOMER + customerId + '/accounting',
    accountingData
  )
}
