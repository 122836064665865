import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useModifierSurveyContext } from '../../pages/Modifier'
import { useModifierSurveyStore } from '../../store/useModifierSurveryStore'
import { OfficeImprovement } from '../../utils/enums/modifierSurveyEnums'
import { Button } from '../core/Button'
import { QuestionWidget } from './QuestionWidget'

export interface SurveyQuestionOfficeImprovementProps {
  editable: boolean
}

export const SurveyQuestionOfficeImprovement = ({
  editable,
}: SurveyQuestionOfficeImprovementProps) => {
  const context = useModifierSurveyContext()
  const { t } = useTranslation(['modifierSurvey'])
  const options = Object.keys(OfficeImprovement).map((value: string) => ({
    name: t(`modifierSurvey:officeImprovement.${value}`),
    value,
  }))
  const [selectedValue, setSelectedValue] = useState<
    OfficeImprovement | undefined
  >(undefined)

  const officeImprovement =
    useModifierSurveyStore().modifierResults?.officeImprovement
  const officeImprovementText =
    useModifierSurveyStore().modifierResults?.officeImprovementText
  const setOfficeImprovement = useModifierSurveyStore().setOfficeImprovement
  const setOfficeImprovementText =
    useModifierSurveyStore().setOfficeImprovementText

  useEffect(() => {
    setSelectedValue(officeImprovement)
  }, [officeImprovement])

  const onSelect = (selectedValue: string) => {
    setOfficeImprovement(selectedValue as OfficeImprovement)
  }

  const onCommentInput = (comment: string) => {
    setOfficeImprovementText(comment)
  }

  return (
    <>
      <div
        className={classNames(editable ? 'pt-8 w-full px-8 py-16' : 'w-full')}
      >
        <QuestionWidget
          options={options}
          selectedValue={selectedValue}
          onSelect={onSelect}
          onCommentInput={onCommentInput}
          note={officeImprovementText}
          editable={editable}
        />
      </div>
      {editable && (
        <div className="bg-gray-100 py-4 flex items-center justify-between w-full px-8">
          <Button
            onClick={() => context?.onPrevTab()}
            label={t('modifierSurvey:common.backBtn')}
            variant="secondary"
          />
          <Button
            onClick={() => context?.onNextTab()}
            label={t('modifierSurvey:common.nextBtn')}
            variant="primary"
            disabled={selectedValue === undefined}
          />
        </div>
      )}
    </>
  )
}
