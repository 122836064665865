import { AgGridReact } from 'ag-grid-react'
import { useEffect, useState } from 'react'
import { getEmissionMapping } from '../../api/emission-mapping/getEmissionMapping'
import { updateEmissionMapping } from '../../api/emission-mapping/updateEmissionMapping'
import { EmissionMapping } from '../../models/emissionMapping'
import { useYearStore } from '../../store/useYearStore'
import { JSONUploadComponent } from './JSONUpload'

export const EmissionMappingTable = () => {
  const [rowData, setRowData] = useState<any[]>()
  const [emissionMapping, setEmissionMapping] = useState<EmissionMapping[]>([])
  const [displayedEmissions, setDisplayedEmissions] = useState<
    EmissionMapping[]
  >([])
  const year = useYearStore((state) => state.year)

  const defaultColDef = {
    sortable: true,
    editable: false,
    resizable: true,
  }
  const columnDefs = [
    {
      field: 'year',
      headerName: 'Jahr',
      sort: 'desc' as any,
      flex: 0.05,
    },
    {
      field: 'country',
      headerName: 'Land',
      filter: 'agTextColumnFilter',
      sort: 'desc' as any,
      flex: 0.075,
    },
    {
      field: 'modifier',
      headerName: 'Modifier',
      filter: 'agTextColumnFilter',
      sort: 'desc' as any,
      flex: 0.1,
    },
    {
      field: 'option',
      headerName: 'Option',
      filter: 'agTextColumnFilter',
      sort: 'desc' as any,
      flex: 0.1,
    },
    {
      field: 'businessCategory',
      headerName: 'Business Category',
      filter: 'agTextColumnFilter',
      sort: 'desc' as any,
      flex: 0.075,
    },
    {
      field: 'emission',
      headerName: 'Emission',
      filter: 'agNumberColumnFilter',
      flex: 0.05,
    },
    {
      field: 'scope',
      headerName: 'scope',
      filter: 'agTextColumnFilter',
      flex: 0.05,
    },
  ]

  useEffect(() => {
    const fetchMapping = async () => {
      const results = await getEmissionMapping()

      if (results.status === 200 && results.data) {
        setEmissionMapping(results.data)
      }
    }

    fetchMapping()
  }, [])

  useEffect(() => {
    setDisplayedEmissions(
      emissionMapping.filter((mapping) => mapping.year === year)
    )
  }, [year, emissionMapping])

  useEffect(() => {
    setRowData(displayedEmissions)
  }, [displayedEmissions])

  return (
    <div className="h-[75vh]">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            Grundwerte für Ausgaben
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Enthält THG Intensitäten pro Biz Kategorie und Modifier pro Jahr pro
            Land
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <JSONUploadComponent
            uploadData={updateEmissionMapping}
            onUploadSuccess={(newMapping) => setEmissionMapping(newMapping)}
          />
        </div>
      </div>

      <div
        className="ag-theme-alpine mt-4 bg-white shadow-sms rounded-md py-4 px-4 h-full"
        style={{ width: '100%', height: '100%' }}
      >
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          onGridReady={(e) => {
            e.api.sizeColumnsToFit({ defaultMinWidth: 200 })
          }}
        />
      </div>
    </div>
  )
}
