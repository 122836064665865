import { RadioGroup } from '@headlessui/react'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export interface QuestionWidgetProps {
  options: SurveyQuestionOption[]
  selectedValue: string | undefined
  note: string | undefined
  onSelect: (selectedValue: string) => void
  onCommentInput: (note: string) => void
  editable?: boolean
}

export interface SurveyQuestionOption {
  name: string
  value: string
  hint?: string
}

interface NotesFormType {
  comment: string
}

export const QuestionWidget = ({
  options,
  selectedValue,
  onSelect,
  onCommentInput,
  note,
  editable,
}: QuestionWidgetProps) => {
  const { t } = useTranslation(['modifierSurvey'])

  const { register } = useForm<NotesFormType>({
    mode: 'onBlur',
  })

  return (
    <div
      className={classNames(
        'grid',
        editable ? 'grid-cols-12 gap-12' : 'grid-cols-1'
      )}
    >
      <RadioGroup
        value={selectedValue === undefined ? null : selectedValue}
        onChange={(e: any) => onSelect(e)}
        className={classNames(editable ? 'col-span-7' : 'col-span-1')}
        disabled={!editable}
      >
        {editable && (
          <RadioGroup.Label className="block text-sm font-medium text-gray-500">
            {t('modifierSurvey:common.chooseOption')}
          </RadioGroup.Label>
        )}
        <div className="space-y-4 mt-1">
          {options.map((option: SurveyQuestionOption) => (
            <RadioGroup.Option
              key={option.name}
              value={option.value}
              className={({ checked, active }) =>
                classNames(
                  checked
                    ? 'border-transparent !bg-brand-light '
                    : 'border-gray-300',
                  active ? 'border-brand-light ring-2 ring-brand-light ' : '',
                  editable ? 'cursor-pointer' : '',
                  'relative block  rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between'
                )
              }
            >
              {({ active, checked }) => (
                <>
                  <span className="flex items-center">
                    <span className="flex flex-col text-lg">
                      <RadioGroup.Label
                        as="span"
                        className="font-medium text-gray-900"
                      >
                        {option.name}
                      </RadioGroup.Label>
                      {option.hint && (
                        <RadioGroup.Description
                          as="span"
                          className="text-gray-500 text-sm"
                        >
                          <span className="block sm:inline">{option.hint}</span>
                        </RadioGroup.Description>
                      )}
                    </span>
                  </span>

                  <span
                    className={classNames(
                      active ? 'border' : 'border-2',
                      checked ? 'border-brand-light' : 'border-transparent',
                      'pointer-events-none absolute -inset-px rounded-lg'
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
      {editable && (
        <form className="flex flex-col w-full items-start justify-start col-span-5">
          <label
            htmlFor="comment"
            className="block text-sm font-medium text-gray-500"
          >
            {t('modifierSurvey:common.addNotes')}
          </label>
          <div className="mt-1 w-full">
            <textarea
              rows={4}
              {...register('comment')}
              id="comment"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand focus:ring-brand-light sm:text-sm"
              defaultValue={note || ''}
              onBlur={(e) => onCommentInput(e.target.value)}
            />
          </div>
        </form>
      )}
    </div>
  )
}
