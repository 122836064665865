export enum Country {
  AUSTRIA = 'AUSTRIA',
  BELGIUM = 'BELGIUM',
  BULGARIA = 'BULGARIA',
  CROATIA = 'CROATIA',
  CYPRUS = 'CYPRUS',
  CZECH_REPUBLIC = 'CZECH_REPUBLIC',
  DENMARK = 'DENMARK',
  ESTONIA = 'ESTONIA',
  FINLAND = 'FINLAND',
  FRANCE = 'FRANCE',
  GERMANY = 'GERMANY',
  GREECE = 'GREECE',
  HUNGARY = 'HUNGARY',
  ICELAND = 'ICELAND',
  IRELAND = 'IRELAND',
  ITALY = 'ITALY',
  LATVIA = 'LATVIA',
  LITHUANIA = 'LITHUANIA',
  LUXEMBOURG = 'LUXEMBOURG',
  MALTA = 'MALTA',
  NETHERLANDS = 'NETHERLANDS',
  NORWAY = 'NORWAY',
  POLAND = 'POLAND',
  PORTUGAL = 'PORTUGAL',
  ROMANIA = 'ROMANIA',
  SLOVAKIA = 'SLOVAKIA',
  SLOVENIA = 'SLOVENIA',
  SPAIN = 'SPAIN',
  SWEDEN = 'SWEDEN',
  SWITZERLAND = 'SWITZERLAND',
  UNITED_KINGDOM = 'UNITED_KINGDOM',
}
