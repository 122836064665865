import { Bar } from 'react-chartjs-2'
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  Tooltip,
} from 'chart.js'
import { formatNumber } from '../../utils/formatNumber'
import { useTranslation } from 'react-i18next'

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip)

export interface ScopeChartProps {
  scope1: number
  scope2: number
  scope3: number
}

export const ScopeChart = ({ scope1, scope2, scope3 }: ScopeChartProps) => {
  const { t } = useTranslation()

  const avgScope1 = 0.32
  const avgScope2 = 0.05
  const avgScope3 = 0.63

  const options = {
    indexAxis: 'y' as const,
    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (e: any) => {
            const labels =
              (e.dataset.label as string).includes('Durchschnitt') ||
              (e.dataset.label as string).includes('Average')
                ? [
                    t('dashboard.charts.scopeTooltip1'),
                    t('dashboard.charts.scopeTooltip2'),
                    t('dashboard.charts.scopeTooltip3'),
                  ]
                : [e.dataset.label + ':']

            return [...labels, formatNumber(e.raw * 100, 2) + '%']
          },
        },
      },
    },
    scales: {
      y: {
        ticks: {
          mirror: false,
          color: 'black',
          font: {
            size: 14,
            family: 'sans-serif',
          },
        },
        grid: {
          display: false,
        },
      },
      x: {
        display: false,
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    barThickness: 20,
  }

  const labels = ['Scope 1', 'Scope 2', 'Scope 3']

  const data = {
    labels,
    datasets: [
      {
        label: t('dashboard.charts.us'),
        data: [scope1, scope2, scope3],
        backgroundColor: 'rgba(59, 130, 246, 0.5)',
        borderRadius: 8,
      },
      {
        label: t('dashboard.charts.average'),
        data: [avgScope1, avgScope2, avgScope3],
        backgroundColor: 'rgba(107, 114, 128, 0.5)',
        borderRadius: 8,
      },
    ],
  }

  return (
    <div className="grid grid-cols-2 gap-12">
      <div>
        <span className="truncate text-sm font-medium text-gray-500 w-full justify-between flex">
          Scopes
        </span>

        <div className="h-52">
          <Bar options={options} data={data} />
        </div>
      </div>
      <div>
        <ul className="pt-2">
          <li className="py-1">
            <span className="font-bold">Scope 1 </span>
            <span className="text-gray-700">
              {t('dashboard.charts.scope1')}
            </span>
          </li>
          <li className="py-1">
            <span className="font-bold">Scope 2 </span>
            <span className="text-gray-700">
              {t('dashboard.charts.scope2')}
            </span>
          </li>
          <li className="py-1">
            <span className="font-bold">Scope 3 Upstream </span>
            <span className="text-gray-700">
              {t('dashboard.charts.scope3')}
            </span>
          </li>
        </ul>
      </div>
    </div>
  )
}
