import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  Tooltip,
} from 'chart.js'
import classNames from 'classnames'
import { Bar } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'
import { formatNumber } from '../../utils/formatNumber'
import { PercentChangePill } from './percentChangePill'

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip)

export interface FootprintPerEmployeeChartProps {
  footprintTotal: number
  employeeCount: number
}

export const FootprintPerEmployeeChart = ({
  footprintTotal,
  employeeCount,
}: FootprintPerEmployeeChartProps) => {
  const { t } = useTranslation()

  const average = 9.65
  const footprint = Math.round((footprintTotal / employeeCount) * 100) / 100

  const data = {
    labels: [
      `${t('dashboard.charts.us')}: ${formatNumber(footprint)} t`,
      `${t('dashboard.charts.average')}: ${formatNumber(average)} t`,
    ],
    datasets: [
      {
        data: [footprint, average],
        backgroundColor: [
          average > footprint
            ? 'rgba(135, 192, 76, 0.5)'
            : 'rgba(153, 27, 27, 0.25)',
          'rgba(107, 114, 128, 0.5)',
        ],
        borderRadius: 8,
      },
    ],
  }

  const options = {
    indexAxis: 'y' as const,
    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    scales: {
      y: {
        ticks: {
          mirror: true,
          color: 'black',
          font: {
            size: 14,
            family: 'sans-serif',
          },
        },
        grid: {
          display: false,
        },
      },
      x: {
        display: false,
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: (e: any) => {
            if (
              !(e[0].label as string).includes('Durchschnitt') &&
              !(e[0].label as string).includes('Average')
            ) {
              return e[0].label
            }

            const parts = e[0].label.split(': ')

            return [
              t('dashboard.charts.scopeTooltip1'),
              t('dashboard.charts.scopeTooltip2'),
              t('dashboard.charts.scopeTooltip3'),
              parts[1],
            ]
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    barThickness: 30,
  }

  return (
    <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 flex flex-col">
      <dt className="text-sm font-medium text-gray-500 w-full justify-between flex">
        <span className="w-1/2">{t('dashboard.charts.perEmployeeTitle')}</span>
        <PercentChangePill footprint={footprint} average={average} />
      </dt>
      <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900 flex justify-between w-full items-end">
        <span>
          <span
            className={classNames(
              footprint > average ? 'text-red-800' : 'text-brand-dark'
            )}
          >
            {formatNumber(footprint)} t
          </span>
          <span className="text-gray-400 text-lg pl-2"> CO₂E</span>
        </span>
      </dd>
      <dd className="pt-4 -ml-4 max-h-28">
        <Bar options={options} data={data} />
      </dd>
    </div>
  )
}
