import { useAuthState } from 'react-firebase-hooks/auth'
import { Navigate, useLocation } from 'react-router-dom'
import { auth } from '../../firebase.config'
import { useCustomerStore } from '../../store/useCustomerStore'
import { useUserStore } from '../../store/useUserStore'
import { PATHS } from '../../utils/enums/paths'

export const ProtectedRoute = (props: React.PropsWithChildren<any>) => {
  const [user, loading] = useAuthState(auth)
  const customer = useCustomerStore((state) => state.customer)
  const dbUser = useUserStore((state) => state.user)
  const location = useLocation()

  if (!loading && (!user || !user.emailVerified)) {
    return <Navigate to={PATHS.LOGIN} />
  }

  if (
    location.pathname !== PATHS.ONBOARDING &&
    (!dbUser?.firstName ||
      !dbUser.lastName ||
      !customer?.accountingSoftware ||
      !customer.companyName ||
      !customer.country ||
      !customer.industry)
  ) {
    return <Navigate to={PATHS.ONBOARDING} />
  }

  return <>{props.children}</>
}
