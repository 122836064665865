export enum AccountingSoftware {
  DATEV_UNTERNEHMEN_ONLINE = 'DATEV_UNTERNEHMEN_ONLINE',
  DATEV_MITTELSTAND = 'DATEV_MITTELSTAND',
  WISO_VON_BUHL = 'WISO_VON_BUHL',
  SAGE_ERP = 'SAGE_ERP',
  SAGE_100 = 'SAGE_100',
  LEXWARE = 'LEXWARE',
  X360 = 'X360',
  ADDISON = 'ADDISON',
  CANDIS = 'CANDIS',
  DIAMANT = 'DIAMANT',
  BILLOMAT = 'BILLOMAT',
  ODOO = 'ODOO',
  OTHER = 'OTHER',
}
