import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { User } from '../../models/user'
import { AdminPage } from '../../pages/Admin'
import { DashboardPage } from '../../pages/Dashboard'
import { EnglishTermsPage } from '../../pages/EnglishTerms'
import LoginPage from '../../pages/Login'
import { LogoutPage } from '../../pages/Logout'
import { MeasuresPage } from '../../pages/Measures'
import { ModifierPage } from '../../pages/Modifier'
import { OnboardingPage } from '../../pages/Onboarding'
import { ProfilePage } from '../../pages/ProfilePage'
import { RegistrationPage } from '../../pages/Registration'
import { ReportsPage } from '../../pages/Reports'
import { SuppliersPage } from '../../pages/Suppliers'
import { TermsPage } from '../../pages/Terms'
import { TransactionsPage } from '../../pages/TransactionsPage'
import {
  ADMIN_PATHS,
  DASHBOARD_PATHS,
  MODIFIER_PATHS,
  PATHS,
} from '../../utils/enums/paths'
import { EmissionMappingTable } from '../admin/EmissionMappingTable'
import { EmployeeCostTable } from '../admin/EmployeeCostTable'
import { FakeCustomerLogin } from '../admin/FakeCustomerLogin'
import { FinalReportDashboard } from '../dashboard/FinalReportDashboard'
import { LiveReportDashboard } from '../dashboard/LiveReportDashboard'
import { SurveyQuestionConsumption } from '../modifier/SurveyQuestionConsumption'
import { SurveyQuestionDietKind } from '../modifier/SurveyQuestionDietKind'
import { SurveyQuestionElectronics } from '../modifier/SurveyQuestionElectronics'
import { SurveyQuestionFuel } from '../modifier/SurveyQuestionFuel'
import { SurveyQuestionOfficeImprovement } from '../modifier/SurveyQuestionFurniture'
import { SurveyQuestionHeating } from '../modifier/SurveyQuestionHeating'
import { SurveyQuestionHotels } from '../modifier/SurveyQuestionHotels'
import { SurveyQuestionIntro } from '../modifier/SurveyQuestionIntro'
import { SurveyQuestionOrganicProducts } from '../modifier/SurveyQuestionOrganicProducts'
import { SurveyQuestionRenewableEnergyShare } from '../modifier/SurveyQuestionRenewableEnergyShare'
import { SurveyQuestionRent } from '../modifier/SurveyQuestionRent'
import { SurveyQuestionResults } from '../modifier/SurveyQuestionResults'
import { SurveyQuestionRevenue } from '../modifier/SurveyQuestionRevenue'
import { AdminRoute } from './AdminRoute'
import { ProtectedRoute } from './ProtectedRoute'
import { Sidebar } from './Sidebar'

export interface RouteConfigProps {
  user: User | undefined | null
}

export const RouteConfig = ({ user }: RouteConfigProps) => {
  const { search } = useLocation()

  return (
    <Routes>
      <Route
        element={
          <ProtectedRoute>
            <Sidebar />
          </ProtectedRoute>
        }
      >
        <Route path={PATHS.DASHBOARD} element={<DashboardPage />}>
          <Route
            index
            element={
              <Navigate to={PATHS.DASHBOARD + DASHBOARD_PATHS.LIVE + search} />
            }
          />
          <Route
            path={PATHS.DASHBOARD + DASHBOARD_PATHS.FINAL}
            element={<FinalReportDashboard />}
          />
          <Route
            path={PATHS.DASHBOARD + DASHBOARD_PATHS.LIVE}
            element={<LiveReportDashboard />}
          />
        </Route>
        <Route path={PATHS.MEASURES} element={<MeasuresPage />} />
        <Route
          path={PATHS.ADMIN}
          element={
            <AdminRoute user={user}>
              <AdminPage />
            </AdminRoute>
          }
        >
          <Route
            index
            element={
              <Navigate
                to={PATHS.ADMIN + ADMIN_PATHS.CUSTOMER_LOGIN + search}
              />
            }
          />
          <Route
            path={PATHS.ADMIN + ADMIN_PATHS.CUSTOMER_LOGIN}
            element={<FakeCustomerLogin />}
          ></Route>
          <Route
            path={PATHS.ADMIN + ADMIN_PATHS.EMPLOYEE_COSTS}
            element={<EmployeeCostTable />}
          ></Route>
          <Route
            path={PATHS.ADMIN + ADMIN_PATHS.EMISSION_MAPPING}
            element={<EmissionMappingTable />}
          ></Route>
        </Route>
        <Route path={PATHS.MODIFIER} element={<ModifierPage />}>
          <Route
            path={PATHS.MODIFIER}
            element={<SurveyQuestionIntro />}
          ></Route>
          <Route
            path={PATHS.MODIFIER + MODIFIER_PATHS.REVENUE}
            element={<SurveyQuestionRevenue editable />}
          ></Route>
          <Route
            path={PATHS.MODIFIER + MODIFIER_PATHS.FUEL}
            element={<SurveyQuestionFuel editable />}
          ></Route>
          <Route
            path={PATHS.MODIFIER + MODIFIER_PATHS.HEATING}
            element={<SurveyQuestionHeating editable />}
          ></Route>
          <Route
            path={PATHS.MODIFIER + MODIFIER_PATHS.ELECTRICITY}
            element={<SurveyQuestionRenewableEnergyShare editable />}
          ></Route>
          <Route
            path={PATHS.MODIFIER + MODIFIER_PATHS.RENT}
            element={<SurveyQuestionRent editable />}
          ></Route>
          <Route
            path={PATHS.MODIFIER + MODIFIER_PATHS.FOOD}
            element={<SurveyQuestionDietKind editable />}
          ></Route>
          <Route
            path={PATHS.MODIFIER + MODIFIER_PATHS.BIO}
            element={<SurveyQuestionOrganicProducts editable />}
          ></Route>
          <Route
            path={PATHS.MODIFIER + MODIFIER_PATHS.FURNITURE}
            element={<SurveyQuestionOfficeImprovement editable />}
          ></Route>
          <Route
            path={PATHS.MODIFIER + MODIFIER_PATHS.ELECTRONICS}
            element={<SurveyQuestionElectronics editable />}
          ></Route>
          <Route
            path={PATHS.MODIFIER + MODIFIER_PATHS.HOTELS}
            element={<SurveyQuestionHotels editable />}
          ></Route>
          <Route
            path={PATHS.MODIFIER + MODIFIER_PATHS.CONSUMPTION}
            element={<SurveyQuestionConsumption />}
          ></Route>
          <Route
            path={PATHS.MODIFIER + MODIFIER_PATHS.RESULTS}
            element={<SurveyQuestionResults />}
          ></Route>
        </Route>
        <Route path={PATHS.REPORTS} element={<ReportsPage />} />
        <Route path={PATHS.TRANSACTIONS} element={<TransactionsPage />} />
        <Route path={PATHS.SUPPLIERS} element={<SuppliersPage />} />
        <Route path={PATHS.ONBOARDING} element={<OnboardingPage />} />
        <Route path={PATHS.PROFILE} element={<ProfilePage />} />
      </Route>
      <Route path={PATHS.TERMS} element={<TermsPage />} />
      <Route path={PATHS.TERMS_EN} element={<EnglishTermsPage />} />
      <Route path={PATHS.LOGIN} element={<LoginPage />} />
      <Route path={PATHS.REGISTRATION} element={<RegistrationPage />} />
      <Route path={PATHS.LOGOUT} element={<LogoutPage />} />
      <Route path="*" element={<Navigate to={PATHS.DASHBOARD + search} />} />
    </Routes>
  )
}
