import React from 'react'
import { Navigate } from 'react-router-dom'
import { User } from '../../models/user'
import { PATHS } from '../../utils/enums/paths'

interface AdminRouteProps {
  user: User | undefined | null
}

export const AdminRoute = ({
  user,
  children,
}: React.PropsWithChildren<AdminRouteProps>) => {
  if (!user?.admin) {
    return <Navigate to={PATHS.DASHBOARD} />
  }

  return <>{children}</>
}
