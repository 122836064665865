import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SurveyQuestionDietKind } from '../modifier/SurveyQuestionDietKind'
import { SurveyQuestionElectronics } from '../modifier/SurveyQuestionElectronics'
import { SurveyQuestionFuel } from '../modifier/SurveyQuestionFuel'
import { SurveyQuestionOfficeImprovement } from '../modifier/SurveyQuestionFurniture'
import { SurveyQuestionHeating } from '../modifier/SurveyQuestionHeating'
import { SurveyQuestionHotels } from '../modifier/SurveyQuestionHotels'
import { SurveyQuestionOrganicProducts } from '../modifier/SurveyQuestionOrganicProducts'
import { SurveyQuestionRenewableEnergyShare } from '../modifier/SurveyQuestionRenewableEnergyShare'
import { SurveyQuestionRent } from '../modifier/SurveyQuestionRent'
import { SurveyQuestionRevenue } from '../modifier/SurveyQuestionRevenue'

const components = [
  {
    name: 'modifierSurvey:revenue.tab',
    component: <SurveyQuestionRevenue editable={false} />,
  },
  {
    name: 'modifierSurvey:fuel.tab',
    component: <SurveyQuestionFuel editable={false} />,
  },
  {
    name: 'modifierSurvey:heating.tab',
    component: <SurveyQuestionHeating editable={false} />,
  },
  {
    name: 'modifierSurvey:renewableEnergyShare.tab',
    component: <SurveyQuestionRenewableEnergyShare editable={false} />,
  },
  {
    name: 'modifierSurvey:rent.tab',
    component: <SurveyQuestionRent editable={false} />,
  },
  {
    name: 'modifierSurvey:dietKind.tab',
    component: <SurveyQuestionDietKind editable={false} />,
  },
  {
    name: 'modifierSurvey:organicProducts.tab',
    component: <SurveyQuestionOrganicProducts editable={false} />,
  },
  {
    name: 'modifierSurvey:officeImprovement.tab',
    component: <SurveyQuestionOfficeImprovement editable={false} />,
  },
  {
    name: 'modifierSurvey:electronics.tab',
    component: <SurveyQuestionElectronics editable={false} />,
  },
  {
    name: 'modifierSurvey:hotels.tab',
    component: <SurveyQuestionHotels editable={false} />,
  },
]

export const ModifierOverview = () => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [selectedComponent, setSelectedComponent] = useState(
    components[0].component
  )
  const { t } = useTranslation(['modifierSurvey'])

  return (
    <div className="grid grid-cols-4 gap-8 overflow-hidden pt-2">
      <nav className="space-y-1" aria-label="Sidebar">
        {components.map((item, index) => (
          <span
            key={item.name}
            className={classNames(
              selectedIndex === index
                ? 'bg-gray-100 text-gray-900'
                : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
              'flex items-center px-3 py-2 text-sm font-medium rounded-md cursor-pointer'
            )}
            aria-current={selectedIndex === index ? 'page' : undefined}
            onClick={() => {
              setSelectedIndex(index)
              setSelectedComponent(components[index].component)
            }}
          >
            <span className="truncate">{t(item.name)}</span>
          </span>
        ))}
      </nav>
      <div className="col-span-3">{selectedComponent}</div>
    </div>
  )
}
