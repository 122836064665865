import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { LinkWithQuery } from '../components/core/LinkWithQuery'
import { PageShell } from '../components/core/PageShell'
import { ADMIN_PATHS, PATHS } from '../utils/enums/paths'

interface Tab {
  name: string
  href: string
  current: boolean
}

const defaultTabs = [
  {
    name: 'Kundenverwaltung',
    href: PATHS.ADMIN + ADMIN_PATHS.CUSTOMER_LOGIN,
    current: true,
  },
  {
    name: 'Emissions Mapping',
    href: PATHS.ADMIN + ADMIN_PATHS.EMISSION_MAPPING,
    current: false,
  },
  {
    name: 'Employee costs',
    href: PATHS.ADMIN + ADMIN_PATHS.EMPLOYEE_COSTS,
    current: false,
  },
]

export const AdminPage = () => {
  const [tabs, setTabs] = useState(defaultTabs)
  const location = useLocation()

  useEffect(() => {
    if (location) {
      setTabs((prevState: Tab[]) => {
        return prevState.map((tab: Tab) => {
          if (location.pathname === tab.href) {
            return { ...tab, current: true }
          }

          return { ...tab, current: false }
        })
      })
    }
  }, [location])

  return (
    <PageShell title="Admin">
      <div className="block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <LinkWithQuery
                key={tab.name}
                to={tab.href}
                className={classNames(
                  tab.current
                    ? 'border-brand text-brand-dark'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                )}
                aria-current={tab.current ? 'page' : undefined}
              >
                {tab.name}
              </LinkWithQuery>
            ))}
          </nav>
        </div>
      </div>
      <div className="mt-8 h-full">
        <Outlet />
      </div>
    </PageShell>
  )
}
