import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import { getFootprint } from '../api/customer/getFootprint'
import { getSurveyResults } from '../api/customer/getSurveyResults'
import { LinkWithQuery } from '../components/core/LinkWithQuery'
import { LoadingSpinner } from '../components/core/LoadingSpinner'
import { PageShell } from '../components/core/PageShell'
import { Footprint } from '../models/footprint'
import { ModifierSurveyResults } from '../models/modifierSurveyResults'
import { useCustomerStore } from '../store/useCustomerStore'
import { useModifierSurveyStore } from '../store/useModifierSurveryStore'
import { useYearStore } from '../store/useYearStore'
import { DASHBOARD_PATHS, PATHS } from '../utils/enums/paths'

type DashboardContextType = {
  liveFootprint: Footprint | undefined
  finalFootprint: Footprint | undefined
  modifierSurveyResults: ModifierSurveyResults | undefined
}

interface Tab {
  name: string
  href: string
  current: boolean
  hint: string
}

const defaultTabs = [
  {
    name: 'dashboard.liveTab.title',
    href: PATHS.DASHBOARD + DASHBOARD_PATHS.LIVE,
    current: false,
    hint: 'dashboard.liveTab.hint',
  },
  {
    name: 'dashboard.finalTab.title',
    href: PATHS.DASHBOARD + DASHBOARD_PATHS.FINAL,
    current: true,
    hint: 'dashboard.finalTab.hint',
  },
]

export const DashboardPage = () => {
  const { t } = useTranslation()
  const [tabs, setTabs] = useState(defaultTabs)
  const location = useLocation()
  const navigate = useNavigate()
  const { search } = useLocation()

  const customer = useCustomerStore((state) => state.customer)
  const year = useYearStore((state) => state.year)

  const [context, setContext] = useState<DashboardContextType>()
  const setModifierSurveyResults = useModifierSurveyStore(
    (state) => state.setModifierSurveyResults
  )

  useEffect(() => {
    const fetchData = async () => {
      const [footprintResults, modifierResults] = await Promise.allSettled([
        getFootprint(customer!.id, year!).catch((e) => {
          return Promise.reject(e)
        }),
        getSurveyResults(customer!.id, year!).catch((e) => {
          return Promise.reject(e)
        }),
      ])

      if (
        footprintResults.status === 'rejected' ||
        modifierResults.status === 'rejected'
      ) {
        setContext({
          modifierSurveyResults: undefined,
          liveFootprint: undefined,
          finalFootprint: undefined,
        })
      }

      if (
        footprintResults.status === 'fulfilled' &&
        footprintResults.value.status === 200 &&
        footprintResults.value.data &&
        modifierResults.status === 'fulfilled' &&
        modifierResults.value.status === 200 &&
        modifierResults.value.data
      ) {
        setContext({
          liveFootprint: footprintResults.value.data.calculated,
          finalFootprint: footprintResults.value.data.custom,
          modifierSurveyResults: modifierResults.value.data,
        })

        if (footprintResults.value.data.custom) {
          navigate(PATHS.DASHBOARD + DASHBOARD_PATHS.FINAL + search)
        } else {
          navigate(PATHS.DASHBOARD + DASHBOARD_PATHS.LIVE + search)
        }
      }
    }

    if (customer && year) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, year])

  useEffect(() => {
    if (!context?.modifierSurveyResults) {
      return
    }

    setModifierSurveyResults(context?.modifierSurveyResults)
  }, [context, setModifierSurveyResults])

  useEffect(() => {
    if (location) {
      setTabs((prevState: Tab[]) => {
        return prevState.map((tab: Tab) => {
          if (location.pathname === tab.href) {
            return { ...tab, current: true }
          }

          return { ...tab, current: false }
        })
      })
    }
  }, [location])

  if (!context) {
    return <LoadingSpinner />
  }

  return (
    <PageShell title="Dashboard">
      <div className="block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <div className="flex items-center" key={tab.name}>
                <LinkWithQuery
                  to={tab.href}
                  className={classNames(
                    tab.current
                      ? 'border-brand text-brand-dark'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                    'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                  )}
                  aria-current={tab.current ? 'page' : undefined}
                >
                  {t(tab.name)}
                </LinkWithQuery>
                <Tooltip
                  anchorId={`hint-icon-${tab.href}`}
                  place="bottom"
                  className="w-60"
                />

                <div
                  className="w-4 h-4 ml-2 text-gray-500"
                  data-tooltip-content={t(tab.hint).toString()}
                  id={`hint-icon-${tab.href}`}
                >
                  <QuestionMarkCircleIcon />
                </div>
              </div>
            ))}
          </nav>
        </div>
      </div>
      <div className="mt-8 h-full">
        <Outlet context={context} />
      </div>
    </PageShell>
  )
}

export function useDashboardContext() {
  return useOutletContext<DashboardContextType>()
}
