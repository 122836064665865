import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useModifierSurveyContext } from '../../pages/Modifier'
import { useModifierSurveyStore } from '../../store/useModifierSurveryStore'
import { Button } from '../core/Button'
interface RevenueFormType {
  revenue: number
  employeeCount: number
}

export interface SurveyQuestionRevenueProps {
  editable: boolean
}

export const SurveyQuestionRevenue = ({
  editable,
}: SurveyQuestionRevenueProps) => {
  const context = useModifierSurveyContext()
  const { t } = useTranslation('modifierSurvey')

  const setRevenue = useModifierSurveyStore((state) => state.setRevenue)
  const revenue = useModifierSurveyStore(
    (state) => state.modifierResults?.revenue
  )
  const employeeCount = useModifierSurveyStore(
    (state) => state.modifierResults?.employeeCount
  )
  const setEmployeeCount = useModifierSurveyStore(
    (state) => state.setEmployeeCount
  )

  const { register, formState } = useForm<RevenueFormType>({
    mode: 'onBlur',
    defaultValues: {
      revenue,
      employeeCount,
    },
  })

  return (
    <div className="flex flex-col w-full items-start justify-start col-span-5">
      <form
        className={classNames(
          editable
            ? 'grid grid-cols-2 gap-8 pt-8 w-full px-8 py-16'
            : 'w-full grid grid-cols-1 gap-8'
        )}
      >
        <div>
          <label
            htmlFor="employeeCount"
            className="block text-sm font-medium text-gray-700"
          >
            {t('modifierSurvey:revenue.employeeCount')}
          </label>
          <div className="relative mt-1 rounded-md shadow-sm">
            <input
              id="employeeCount"
              {...register('employeeCount', {
                required: 'required',
                min: 1,
              })}
              type="number"
              className="block w-full rounded-md border-gray-300  focus:border-brand focus:ring-brand sm:text-sm"
              placeholder="0"
              onBlur={(e) => setEmployeeCount(Number.parseInt(e.target.value))}
              disabled={!editable}
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="revenue"
            className="block text-sm font-medium text-gray-700"
          >
            {t('modifierSurvey:revenue.revenue')}
          </label>
          <div className="relative mt-1 rounded-md shadow-sm">
            <input
              id="revenue"
              {...register('revenue', {
                required: 'required',
                min: 1,
              })}
              type="number"
              className="block w-full rounded-md border-gray-300 pr-12 focus:border-brand focus:ring-brand sm:text-sm"
              placeholder="0.00"
              onBlur={(e) => setRevenue(Number.parseInt(e.target.value))}
              disabled={!editable}
            />
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <span className="text-gray-500 sm:text-sm" id="price-currency">
                EUR
              </span>
            </div>
          </div>
        </div>
      </form>
      {editable && (
        <div className="bg-gray-100 py-4 flex items-center justify-between w-full px-8">
          <Button
            onClick={() => context?.onPrevTab()}
            label={t('modifierSurvey:common.backBtn')}
            variant="secondary"
          />
          <Button
            onClick={() => context?.onNextTab()}
            disabled={!formState.isValid}
            label={t('modifierSurvey:common.nextBtn')}
            variant="primary"
          />
        </div>
      )}
    </div>
  )
}
