import { RadioGroup } from '@headlessui/react'
import classNames from 'classnames'
import { t } from 'i18next'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useModifierSurveyContext } from '../../pages/Modifier'
import { useModifierSurveyStore } from '../../store/useModifierSurveryStore'
import { Button } from '../core/Button'

export interface RentFormtype {
  electricityShare: number
  heatingShare: number
  heatingKwh: number
  electricityKwh: number
  officeSizeQm: number
  electricityHeatingShareText: string
}

export interface SurveyQuestionRentProps {
  editable: boolean
}

export const SurveyQuestionRent = ({ editable }: SurveyQuestionRentProps) => {
  const context = useModifierSurveyContext()

  const electricityShare = useModifierSurveyStore(
    (state) => state.modifierResults?.electricityShare
  )
  const setElectricityShare = useModifierSurveyStore(
    (state) => state.setElectricityShare
  )
  const heatingShare = useModifierSurveyStore(
    (state) => state.modifierResults?.heatingShare
  )
  const setHeatingShare = useModifierSurveyStore(
    (state) => state.setHeatingShare
  )
  const heatingKwh = useModifierSurveyStore(
    (state) => state.modifierResults?.heatingKwh
  )
  const setHeatingKwh = useModifierSurveyStore((state) => state.setHeatingKwh)
  const electricityKwh = useModifierSurveyStore(
    (state) => state.modifierResults?.electricityKwh
  )
  const setElectricityKwh = useModifierSurveyStore(
    (state) => state.setElectricityKwh
  )
  const officeSizeQm = useModifierSurveyStore(
    (state) => state.modifierResults?.officeSizeQm
  )
  const setOfficeSizeQm = useModifierSurveyStore(
    (state) => state.setOfficeSizeQm
  )
  const electricityHeatingShareText = useModifierSurveyStore(
    (state) => state.modifierResults?.electricityHeatingShareText
  )
  const setElectricityHeatingShareText = useModifierSurveyStore(
    (state) => state.setElectricityHeatingShareText
  )
  const resetWarmRent = useModifierSurveyStore((state) => state.resetWarmRent)
  const resetExactUsage = useModifierSurveyStore(
    (state) => state.resetExactUsage
  )
  const resetGuess = useModifierSurveyStore((state) => state.resetGuess)

  const [selected, setSelected] = useState(
    officeSizeQm ? 2 : electricityKwh || heatingKwh ? 1 : 0
  )

  const { register, setValue } = useForm<RentFormtype>({
    mode: 'onChange',
    defaultValues: {
      electricityShare: electricityShare ? electricityShare * 100 : undefined,
      heatingShare: heatingShare ? heatingShare * 100 : undefined,
      electricityKwh,
      heatingKwh,
      officeSizeQm,
      electricityHeatingShareText,
    },
  })

  const onVariantChange = (variant: number) => {
    setValue('electricityKwh', undefined as any)
    setValue('heatingKwh', undefined as any)
    setValue('electricityShare', undefined as any)
    setValue('heatingShare', undefined as any)
    setValue('electricityHeatingShareText', '')

    setSelected(variant)
  }

  return (
    <>
      <div
        className={classNames(editable ? 'pt-8 w-full px-8 py-16' : 'w-full')}
      >
        <RadioGroup
          value={selected}
          onChange={onVariantChange}
          disabled={!editable}
        >
          <div className="-space-y-px rounded-md bg-white">
            {/** Know exact percentage Share */}
            <RadioGroup.Option
              key={0}
              value={0}
              className={({ checked }) =>
                classNames(
                  selected === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                  checked
                    ? 'bg-gray-50 border-brand-light z-10'
                    : 'border-gray-200',
                  'relative border p-4 flex cursor-pointer focus:outline-none'
                )
              }
            >
              {({ active, checked }) => (
                <div className="flex flex-col">
                  <div className="flex">
                    <span
                      className={classNames(
                        checked
                          ? 'bg-brand border-transparent'
                          : 'bg-white border-gray-300',
                        active ? 'ring-2 ring-offset-2 ring-brand' : '',
                        'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center'
                      )}
                      aria-hidden="true"
                    >
                      <span className="rounded-full bg-white w-1.5 h-1.5" />
                    </span>
                    <span className="ml-3 flex flex-col">
                      <RadioGroup.Label
                        as="span"
                        className={classNames(
                          'text-gray-900',
                          'block text-sm font-medium'
                        )}
                      >
                        {t('modifierSurvey:rent.option1Title')}
                      </RadioGroup.Label>
                    </span>
                  </div>
                  {checked && (
                    <form className="grid grid-cols-2 gap-8 pt-8">
                      <div>
                        <label
                          htmlFor="heatingShare"
                          className="block text-sm font-medium text-gray-700"
                        >
                          {t('modifierSurvey:rent.option1Heating')}
                        </label>
                        <div className="relative mt-1 rounded-md shadow-sm">
                          <input
                            id="heatingShare"
                            {...register('heatingShare')}
                            type="number"
                            className="block w-full rounded-md border-gray-300 pr-12 focus:border-brand focus:ring-brand sm:text-sm"
                            placeholder="0"
                            onBlur={(e) =>
                              setHeatingShare(
                                Number.parseInt(e.target.value) / 100
                              )
                            }
                            disabled={!editable}
                          />
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <span
                              className="text-gray-500 sm:text-sm"
                              id="price-currency"
                            >
                              %
                            </span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="electricityShare"
                          className="block text-sm font-medium text-gray-700"
                        >
                          {t('modifierSurvey:rent.option1Electricity')}
                        </label>
                        <div className="relative mt-1 rounded-md shadow-sm">
                          <input
                            id="electricityShare"
                            {...register('electricityShare')}
                            type="number"
                            className="block w-full rounded-md border-gray-300 pr-12 focus:border-brand focus:ring-brand sm:text-sm"
                            placeholder="0"
                            onBlur={(e) =>
                              setElectricityShare(
                                Number.parseInt(e.target.value) / 100
                              )
                            }
                            disabled={!editable}
                          />
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <span
                              className="text-gray-500 sm:text-sm"
                              id="price-currency"
                            >
                              %
                            </span>
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              )}
            </RadioGroup.Option>

            {/** Know exact consumption */}
            <RadioGroup.Option
              key={1}
              value={1}
              className={({ checked }) =>
                classNames(
                  selected === 1 ? 'rounded-tl-md rounded-tr-md' : '',
                  checked
                    ? 'bg-gray-50 border-brand-light z-10'
                    : 'border-gray-200',
                  'relative border p-4 flex cursor-pointer focus:outline-none'
                )
              }
            >
              {({ active, checked }) => (
                <div className="flex flex-col">
                  <div className="flex">
                    <span
                      className={classNames(
                        checked
                          ? 'bg-brand border-transparent'
                          : 'bg-white border-gray-300',
                        active ? 'ring-2 ring-offset-2 ring-brand' : '',
                        'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center'
                      )}
                      aria-hidden="true"
                    >
                      <span className="rounded-full bg-white w-1.5 h-1.5" />
                    </span>
                    <span className="ml-3 flex flex-col">
                      <RadioGroup.Label
                        as="span"
                        className={classNames(
                          'text-gray-900',
                          'block text-sm font-medium'
                        )}
                      >
                        {t('modifierSurvey:rent.option2Title')}
                      </RadioGroup.Label>
                    </span>
                  </div>
                  {checked && (
                    <form className="grid grid-cols-2 gap-8 pt-8">
                      <div>
                        <label
                          htmlFor="electricityShare"
                          className="block text-sm font-medium text-gray-700"
                        >
                          {t('modifierSurvey:rent.option2Heating')}
                        </label>
                        <div className="relative mt-1 rounded-md shadow-sm">
                          <input
                            id="heatingKwh"
                            {...register('heatingKwh')}
                            type="number"
                            className="block w-full rounded-md border-gray-300 pr-12 focus:border-brand focus:ring-brand sm:text-sm"
                            placeholder="0"
                            onBlur={(e) =>
                              setHeatingKwh(Number.parseInt(e.target.value))
                            }
                            disabled={!editable}
                          />
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <span
                              className="text-gray-500 sm:text-sm"
                              id="price-currency"
                            >
                              kWh
                            </span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="heatingKwh"
                          className="block text-sm font-medium text-gray-700"
                        >
                          {t('modifierSurvey:rent.option2Electricity')}
                        </label>
                        <div className="relative mt-1 rounded-md shadow-sm">
                          <input
                            id="electricityKwh"
                            {...register('electricityKwh')}
                            type="number"
                            className="block w-full rounded-md border-gray-300 pr-12 focus:border-brand focus:ring-brand sm:text-sm"
                            placeholder="0"
                            onBlur={(e) =>
                              setElectricityKwh(Number.parseInt(e.target.value))
                            }
                            disabled={!editable}
                          />
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <span
                              className="text-gray-500 sm:text-sm"
                              id="price-currency"
                            >
                              kWh
                            </span>
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              )}
            </RadioGroup.Option>

            {/** Have to guess */}
            <RadioGroup.Option
              key={2}
              value={2}
              className={({ checked }) =>
                classNames(
                  selected === 2 ? 'rounded-tl-md rounded-tr-md' : '',
                  checked
                    ? 'bg-gray-50 border-brand-light z-10'
                    : 'border-gray-200',
                  'relative border p-4 flex cursor-pointer focus:outline-none'
                )
              }
            >
              {({ active, checked }) => (
                <div className="flex flex-col">
                  <div className="flex">
                    <span
                      className={classNames(
                        checked
                          ? 'bg-brand border-transparent'
                          : 'bg-white border-gray-300',
                        active ? 'ring-2 ring-offset-2 ring-brand' : '',
                        'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center'
                      )}
                      aria-hidden="true"
                    >
                      <span className="rounded-full bg-white w-1.5 h-1.5" />
                    </span>
                    <span className="ml-3 flex flex-col">
                      <RadioGroup.Label
                        as="span"
                        className={classNames(
                          'text-gray-900',
                          'block text-sm font-medium'
                        )}
                      >
                        {t('modifierSurvey:rent.option3Title')}
                      </RadioGroup.Label>
                    </span>
                  </div>
                  {checked && (
                    <form className="grid grid-cols-6 gap-8 pt-8">
                      <div className="col-span-2">
                        <label
                          htmlFor="officeSizeQm"
                          className="block text-sm font-medium text-gray-700"
                        >
                          {t('modifierSurvey:rent.option3Office')}
                        </label>
                        <div className="relative mt-1 rounded-md shadow-sm">
                          <input
                            id="officeSizeQm"
                            {...register('officeSizeQm')}
                            type="number"
                            className="block w-full rounded-md border-gray-300 pr-12 focus:border-brand focus:ring-brand sm:text-sm"
                            placeholder="0"
                            onBlur={(e) =>
                              setOfficeSizeQm(Number.parseInt(e.target.value))
                            }
                            disabled={!editable}
                          />
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <span
                              className="text-gray-500 sm:text-sm"
                              id="price-currency"
                            >
                              qm
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-4">
                        <label
                          htmlFor="electricityHeatingShareText"
                          className="block text-sm font-medium text-gray-700"
                        >
                          {t('modifierSurvey:rent.option3Text')}
                        </label>
                        <div className="relative mt-1 rounded-md shadow-sm">
                          <textarea
                            rows={4}
                            {...register('electricityHeatingShareText')}
                            id="electricityHeatingShareText"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand focus:ring-brand sm:text-sm"
                            onBlur={(e) =>
                              setElectricityHeatingShareText(e.target.value)
                            }
                            disabled={!editable}
                          />
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              )}
            </RadioGroup.Option>
          </div>
        </RadioGroup>
      </div>
      {editable && (
        <div className="bg-gray-100 py-4 flex items-center justify-between w-full px-8">
          <Button
            onClick={() => context?.onPrevTab()}
            label={t('modifierSurvey:common.backBtn')}
            variant="secondary"
          />
          <Button
            onClick={() => {
              if (selected === 0) {
                resetExactUsage()
                resetGuess()
              }

              if (selected === 1) {
                resetWarmRent()
                resetGuess()
              }

              if (selected === 2) {
                resetWarmRent()
                resetExactUsage()
              }

              context?.onNextTab()
            }}
            label={t('modifierSurvey:common.nextBtn')}
            variant="primary"
          />
        </div>
      )}
    </>
  )
}
