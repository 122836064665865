import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import Decimal from 'decimal.js'
import { useTranslation } from 'react-i18next'
import { formatNumber } from '../../utils/formatNumber'

export interface PercentChangePillProps {
  footprint: number
  average: number
}

export const PercentChangePill = ({
  footprint,
  average,
}: PercentChangePillProps) => {
  const { t } = useTranslation()

  const footprintDecimal = new Decimal(footprint)
  const averageDecimal = new Decimal(average)
  const percentChange = averageDecimal
    .minus(footprintDecimal)
    .dividedBy(averageDecimal)
    .times(100)
    .absoluteValue()
    .toNumber()

  return (
    <div
      className={classNames(
        footprint < average
          ? 'bg-green-100 text-green-800'
          : 'bg-red-100 text-red-800',
        'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-xs h-6 leading-5'
      )}
    >
      {footprint > average ? (
        <ArrowUpIcon
          className="-ml-1 mr-0.5 h-4 w-4 flex-shrink-0 self-center text-red-500"
          aria-hidden="true"
        />
      ) : (
        <ArrowDownIcon
          className="-ml-1 mr-0.5 h-4 w-4 flex-shrink-0 self-center text-green-500"
          aria-hidden="true"
        />
      )}

      {formatNumber(percentChange, 0) + ' %'}
      {' ' +
        (footprint > average
          ? t('dashboard.charts.higher')
          : t('dashboard.charts.less'))}
    </div>
  )
}
