import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useModifierSurveyContext } from '../../pages/Modifier'
import { useModifierSurveyStore } from '../../store/useModifierSurveryStore'
import { PATHS } from '../../utils/enums/paths'
import { Button } from '../core/Button'
import { LoadingSpinner } from '../core/LoadingSpinner'

interface ResultSummary {
  categoryName: string
  categoryResult: string
  categoryNotes: string | undefined
}

export const SurveyQuestionResults = () => {
  const { onPrevTab, onNextTab } = useModifierSurveyContext()
  const { t } = useTranslation('modifierSurvey')
  const navigate = useNavigate()
  const { search } = useLocation()

  const [results, setResults] = useState<ResultSummary[]>([])
  const modifierSurveyResults = useModifierSurveyStore(
    (state) => state.modifierResults
  )

  useEffect(() => {
    if (
      !modifierSurveyResults?.employeeCount ||
      !modifierSurveyResults?.revenue ||
      !modifierSurveyResults?.dietKind ||
      !modifierSurveyResults?.heatingKind
    ) {
      navigate(PATHS.MODIFIER + search)
    }
  }, [modifierSurveyResults, navigate, search])

  useEffect(() => {
    if (modifierSurveyResults) {
      setResults(() => [
        {
          categoryName: 'modifierSurvey:revenue.tab',
          categoryResult: `${modifierSurveyResults.revenue} € / ${modifierSurveyResults.employeeCount}`,
          categoryNotes: undefined,
        },
        {
          categoryName: 'modifierSurvey:fuel.tab',
          categoryResult: `modifierSurvey:fuel.${modifierSurveyResults.gasAndFuel}`,
          categoryNotes: modifierSurveyResults.gasAndFuelText || '-',
        },
        {
          categoryName: 'modifierSurvey:heating.tab',
          categoryResult: `modifierSurvey:heating.${modifierSurveyResults.heatingKind}`,
          categoryNotes: modifierSurveyResults.heatingKindText || '-',
        },
        {
          categoryName: 'modifierSurvey:renewableEnergyShare.tab',
          categoryResult: `modifierSurvey:renewableEnergyShare.${modifierSurveyResults.renewableEnergyShare}`,
          categoryNotes: modifierSurveyResults.renewableEnergyShareText || '-',
        },
        {
          categoryName: 'modifierSurvey:rent.tab',
          categoryResult: !!modifierSurveyResults.officeSizeQm
            ? 'modifierSurvey:summary.estimation'
            : !!modifierSurveyResults.heatingShare
            ? 'modifierSurvey:summary.heatingElectricitySummary'
            : 'modifierSurvey:summary.heatingElectricityKwhSummary',
          categoryNotes: '-',
        },
        {
          categoryName: 'modifierSurvey:dietKind.tab',
          categoryResult: `modifierSurvey:dietKind.${modifierSurveyResults.dietKind}`,
          categoryNotes: modifierSurveyResults.dietKindText || '-',
        },
        {
          categoryName: 'modifierSurvey:organicProducts.tab',
          categoryResult: `modifierSurvey:organicProducts.${modifierSurveyResults.organicProducts}`,
          categoryNotes: modifierSurveyResults.organicProductsText || '-',
        },
        {
          categoryName: 'modifierSurvey:officeImprovement.tab',
          categoryResult: `modifierSurvey:officeImprovement.${modifierSurveyResults.officeImprovement}`,
          categoryNotes: modifierSurveyResults.officeImprovementText || '-',
        },
        {
          categoryName: 'modifierSurvey:electronics.tab',
          categoryResult: `modifierSurvey:electronics.${modifierSurveyResults.electronics}`,
          categoryNotes: modifierSurveyResults.electronicsText || '-',
        },
        {
          categoryName: 'modifierSurvey:hotels.tab',
          categoryResult: `modifierSurvey:hotels.${modifierSurveyResults.hotels}`,
          categoryNotes: modifierSurveyResults.hotelsText || '-',
        },
        {
          categoryName: 'modifierSurvey:consumption.tab',
          categoryResult: modifierSurveyResults.otherText,
          categoryNotes: undefined,
        },
      ])
    } else {
      navigate(PATHS.MODIFIER + search)
    }
  }, [modifierSurveyResults, navigate, search])

  if (!results) {
    return <LoadingSpinner />
  }

  return (
    <>
      <div className="pt-8 w-full px-8 py-16">
        <div className="flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        {t('modifierSurvey:summary.category')}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {t('modifierSurvey:summary.result')}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {t('modifierSurvey:summary.comment')}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {results.map((result) => (
                      <tr key={result.categoryName}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {t(result.categoryName)}
                        </td>
                        <td
                          className="px-3 py-4 text-sm text-gray-500 whitespace-pre-wrap"
                          colSpan={
                            result.categoryName ===
                            'modifierSurvey:consumption.tab'
                              ? 2
                              : 1
                          }
                        >
                          {t(result.categoryResult, {
                            heating: !!modifierSurveyResults.heatingShare
                              ? modifierSurveyResults.heatingShare * 100
                              : !!modifierSurveyResults.heatingKwh
                              ? modifierSurveyResults.heatingKwh
                              : 0,
                            electricity:
                              !!modifierSurveyResults.electricityShare
                                ? modifierSurveyResults.electricityShare * 100
                                : !!modifierSurveyResults.electricityKwh
                                ? modifierSurveyResults.electricityKwh
                                : 0,
                            officeSize: modifierSurveyResults.officeSizeQm,
                          })}
                        </td>
                        {result.categoryName !==
                          'modifierSurvey:consumption.tab' && (
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {result.categoryNotes}
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-100 py-4 flex items-center justify-between w-full px-8">
        <Button
          onClick={() => onPrevTab()}
          label={t('modifierSurvey:common.backBtn')}
          variant="secondary"
        />
        <Button
          onClick={() => onNextTab()}
          label={t('modifierSurvey:common.saveBtn')}
          variant="primary"
        />
      </div>
    </>
  )
}
