import {
  AdjustmentsHorizontalIcon,
  ArrowRightIcon,
} from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'react-tooltip'
import { Footprint } from '../../models/footprint'
import { ModifierSurveyResults } from '../../models/modifierSurveyResults'
import { useDashboardContext } from '../../pages/Dashboard'
import { PATHS } from '../../utils/enums/paths'
import { formatNumber } from '../../utils/formatNumber'
import { LinkWithQuery } from '../core/LinkWithQuery'
import { FootprintChart } from './footprintChart'
import { FootprintPerEmployeeChart } from './footprintPerEmployeeChart'
import { FootprintPerEURChart } from './footprintPerEURChart'
import { ModifierOverview } from './modifierOverview'
import { ScopeChart } from './scopeChart'

export const FinalReportDashboard = () => {
  const context = useDashboardContext()
  const { t } = useTranslation()

  const [footprint, setFootprint] = useState<Footprint>()
  const [surveyResults, setSurveyResults] = useState<ModifierSurveyResults>()
  const [maxCategory, setMaxCategory] = useState<
    { value: number; category: string } | undefined
  >()

  useEffect(() => {
    setFootprint(context.finalFootprint)
    setSurveyResults(context.modifierSurveyResults)

    if (context.finalFootprint) {
      const max = Object.entries(context.finalFootprint)
        .filter(
          (entry) =>
            ![
              'total',
              'revenue',
              'scope1',
              'scope2',
              'scope3',
              'employeeCount',
            ].includes(entry[0])
        )
        .sort((prev, next) => next[1] - prev[1])[0]
      setMaxCategory({ value: max[1], category: max[0] })
    }
  }, [context])

  if (!footprint || !surveyResults) {
    return (
      <div className="text-center pt-16">
        <svg
          className="mx-auto h-12 w-12 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            vectorEffect="non-scaling-stroke"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
          />
        </svg>
        <h3 className="mt-2 text-sm font-medium text-gray-900">
          {t('dashboard.placeholder.title')}
        </h3>
        <p className="mt-1 text-sm text-gray-500 max-w-sm mx-auto">
          {t('dashboard.placeholder.finalReportText')}
        </p>
        <div className="mt-6">
          <LinkWithQuery to={PATHS.TRANSACTIONS}>
            <button
              type="button"
              className="inline-flex items-center rounded-md border border-transparent bg-brand px-4 py-2 text-sm font-medium text-white shadow-sm hover:brand-dark focus:outline-none focus:ring-2 focus:ring-brand focus:ring-offset-2"
            >
              {t('dashboard.placeholder.finalReportButton')}
              <ArrowRightIcon
                className="-mr-1 ml-2 h-5 w-5"
                aria-hidden="true"
              />
            </button>
          </LinkWithQuery>
        </div>
      </div>
    )
  }

  return (
    <div className="grid grid-cols-6 gap-8 max-w-7xl pb-8">
      <div className="col-span-2">
        <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 h-full">
          <dt className="truncate text-sm font-medium text-gray-500">
            {t('dashboard.charts.footprintTotal')}
          </dt>
          <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
            <span className="text-brand-dark">
              {formatNumber(Number.parseInt(footprint.total) / 1000, 0)} t
            </span>
            <span className="text-gray-400 text-lg pl-2"> CO₂E</span>
          </dd>
          {maxCategory && (
            <div>
              <dt className="truncate text-sm font-medium text-gray-500 pt-8">
                {t('dashboard.charts.highestValue')}
              </dt>
              <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                <span className="text-red-700">
                  {formatNumber(
                    (maxCategory?.value /
                      (Number.parseInt(footprint.total) -
                        Number.parseInt(footprint.climateCompensation))) *
                      100,
                    0
                  )}{' '}
                  %
                </span>
                <span className="text-gray-400 text-lg pl-2">
                  {' '}
                  {t(
                    `modifierSurvey:chart.${maxCategory?.category.toLowerCase()}`
                  )}
                </span>
              </dd>
            </div>
          )}
        </div>
      </div>
      <div className="col-span-2">
        <FootprintPerEmployeeChart
          footprintTotal={Number.parseInt(footprint.total) / 1000}
          employeeCount={Number.parseInt(footprint.employeeCount)}
        />
      </div>
      <div className="col-span-2">
        <FootprintPerEURChart
          footprintTotal={Number.parseInt(footprint.total)}
          revenue={Number.parseInt(footprint.revenue)}
        />
      </div>
      <div className="col-span-3">
        <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
          <FootprintChart footprint={footprint} />
        </div>
      </div>
      <div className="col-span-3">
        <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 h-full">
          <dt className="truncate text-sm font-medium text-gray-500 flex justify-between">
            <span>{t('dashboard.charts.usedModifier')}</span>
            <Tooltip
              anchorId="hint-final-report"
              place="top"
              className="w-60 whitespace-pre-line"
            />

            <div
              className=" text-gray-300 bg-gray-50 flex items-center border px-2 rounded-md"
              id="hint-final-report"
              data-tooltip-content="Die Modifier können für den finalen Report nicht nachträglich verändert werden. Wenn Sie Modifier Änderungen vornehmen wollen, nutzen Sie bitte den Live-Report"
            >
              <AdjustmentsHorizontalIcon className="h-6 w-6 pr-2" />
              {t('dashboard.charts.changeModifier')}
            </div>
          </dt>
          <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
            <ModifierOverview />
          </dd>
        </div>
      </div>

      <div className="col-span-6">
        <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 h-full">
          <ScopeChart
            scope1={Number.parseFloat(footprint.scope1)}
            scope2={Number.parseFloat(footprint.scope2)}
            scope3={Number.parseFloat(footprint.scope3)}
          />
        </div>
      </div>
    </div>
  )
}
