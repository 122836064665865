import { User as FirebaseUser } from 'firebase/auth'
import { useEffect, useState } from 'react'
import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { getCustomer } from '../api/customer/getCustomer'
import { getUser } from '../api/user/getUser'
import { LanguageSwitch } from '../components/core/LanguageSwitch'
import { LoadingSpinner } from '../components/core/LoadingSpinner'
import { auth } from '../firebase.config'
import { Customer } from '../models/customer'
import { User } from '../models/user'
import { useCustomerStore } from '../store/useCustomerStore'
import { useUserStore } from '../store/useUserStore'
import { PATHS } from '../utils/enums/paths'
import { initAxios } from '../utils/initAxios'

interface LoginType {
  email: string
  password: string
}

export const LoginPage = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [showEmailWarning, setShowEmailWarning] = useState(false)
  const [loading, setLoading] = useState(false)
  const { register, handleSubmit, setError, formState } = useForm<LoginType>({
    mode: 'onBlur',
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [signInWithEmailAndPassword, user, _, error] =
    useSignInWithEmailAndPassword(auth)
  const setCustomer = useCustomerStore((state) => state.setCustomer)
  const resetCustomer = useCustomerStore((state) => state.resetCustomer)

  const setUser = useUserStore((state) => state.setUser)
  const resetUser = useUserStore((state) => state.resetUser)

  useEffect(() => {
    resetUser()
    resetCustomer()
  }, [resetUser, resetCustomer])

  useEffect(() => {
    if (user && user.user.emailVerified) {
      const fetchCustomer = async (user: FirebaseUser) => {
        const token = await user.getIdToken(true)
        initAxios(token)

        const userData = await getUser(user.uid)
        setUser(userData.data as User)

        const customerId = userData.data.customerId

        const customer = await getCustomer(customerId)
        setCustomer(customer.data as Customer)
      }

      fetchCustomer(user.user)
        .then(() => {
          setLoading(false)
          navigate(PATHS.DASHBOARD, {
            state: { year: new Date().getFullYear() - 1 },
          })
        })
        .catch(() => {
          setLoading(false)
          navigate(PATHS.DASHBOARD, {
            state: { year: new Date().getFullYear() - 1 },
          })
        })
    } else if (user && !user.user.emailVerified) {
      setLoading(false)
      setShowEmailWarning(true)
    }
  }, [user, navigate, setUser, setCustomer, resetUser])

  useEffect(() => {
    if (error) {
      console.error(error)
      setLoading(false)

      let errorMessage = error.code
      if (error.code === 'auth/wrong-password') {
        errorMessage =
          'Falsches Passwort. Bitte überprüfen Sie Ihre Eingabe und versuchen Sie es erneut.'
      } else if (error.code === 'auth/user-not-found') {
        errorMessage =
          'E-Mail Adresse unbekannt. Bitte überprüfen Sie Ihre Eingabe und versuchen Sie es erneut.'
      }

      setError('password', { message: errorMessage })
    }
  }, [error, setError])

  const onSubmit = async (data: LoginType) => {
    setLoading(true)
    await signInWithEmailAndPassword(data.email, data.password)
  }

  if (loading) {
    return <LoadingSpinner></LoadingSpinner>
  }

  return (
    <>
      <div className="flex min-h-full w-full bg-white">
        <div className="flex flex-col h-full justify-between">
          <div className="flex flex-1 h-full flex-col justify-center py-12 lg:flex-none overflow-hidden">
            <div className="mx-auto max-w-md lg:max-w-xl w-[50vw] overflow-y-auto px-4 sm:px-6 lg:px-20 xl:px-24 py-8">
              <div className="flex justify-center items-center">
                <img
                  className="h-24 2xl:h-40 w-auto"
                  src="/logos/ecoincome_logo.svg"
                  alt="eco.income logo"
                />
              </div>
              <h2 className="mt-6 relative text-3xl font-bold tracking-tight text-gray-900 text-center">
                {t('login.login.title')}
              </h2>

              {showEmailWarning ? (
                <div className="mt-8">
                  <h2 className="my-6 relative text-3xl font-bold tracking-tight text-gray-900 text-center">
                    {t('login.login.emailWarningTitle')}
                  </h2>

                  <span className="text-gray-500 text-center w-full block">
                    {t('login.login.emailWarningText')}
                  </span>
                  <button
                    type="submit"
                    className="mt-6 flex w-full justify-center rounded-md border border-transparent bg-brand py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-brand focus:outline-none focus:ring-2 focus:ring-brand focus:ring-offset-2"
                    onClick={() => window.location.reload()}
                  >
                    {t('login.login.emailWarningBtn')}
                  </button>
                </div>
              ) : (
                <div className="mt-8">
                  <div className="mt-6">
                    <form
                      className="space-y-6"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <LanguageSwitch />
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700"
                        >
                          {t('login.login.email')}
                        </label>
                        <div className="mt-1">
                          <input
                            id="email"
                            {...register('email', {
                              required: 'Diese Feld darf nicht leer sein',
                              pattern: {
                                value: /\S+@\S+\.\S+/,
                                message:
                                  'Bitte gib eine gültige E-Mail Adresse ein',
                              },
                            })}
                            type="email"
                            autoComplete="email"
                            required
                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-brand focus:outline-none focus:ring-brand sm:text-sm"
                          />
                          {formState.errors.email && (
                            <p className="text-red-600 bg-red-100 rounded-md px-4 py-1 text-sm mt-1">
                              {formState.errors.email.message}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="space-y-1">
                        <label
                          htmlFor="password"
                          className="block text-sm font-medium text-gray-700"
                        >
                          {t('login.login.password')}
                        </label>
                        <div className="mt-1">
                          <input
                            id="password"
                            {...register('password', {
                              required: 'Diese Feld darf nicht leer sein',
                            })}
                            type="password"
                            autoComplete="current-password"
                            required
                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-brand focus:outline-none focus:ring-brand sm:text-sm"
                          />
                          {formState.errors.password && (
                            <p className="text-red-600 bg-red-100 rounded-md px-4 py-1 text-sm mt-1">
                              {formState.errors.password.message}
                            </p>
                          )}
                        </div>
                      </div>

                      <div>
                        <button
                          type="submit"
                          disabled={!formState.isValid}
                          className="flex w-full justify-center rounded-md border disabled:bg-gray-300 border-transparent bg-brand py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-brand focus:outline-none focus:ring-2 focus:ring-brand focus:ring-offset-2"
                        >
                          {t('login.login.loginBtn')}
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="relative mt-6">
                    <div
                      className="absolute inset-0 flex items-center"
                      aria-hidden="true"
                    >
                      <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="relative flex justify-center text-sm">
                      <span className="bg-white px-2 text-gray-500">
                        {t('login.login.registerHint')}
                      </span>
                    </div>
                  </div>

                  <div>
                    <a href="/registration">
                      <button
                        type="submit"
                        className="mt-6 flex w-full justify-center rounded-md border border-transparent bg-gray-100 py-2 px-4 text-sm font-medium text-black shadow-sm hover:bg-brand-light focus:outline-none focus:ring-2 focus:ring-brand-light focus:ring-offset-2"
                      >
                        {t('login.login.registerBtn')}
                      </button>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="/img/registration_bg.jpeg"
            alt=""
          />
        </div>
      </div>
    </>
  )
}

export default LoginPage
