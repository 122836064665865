import {
  ExclamationCircleIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline'
import { AxiosError } from 'axios'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from 'react-router-dom'
import { getSurveyResults } from '../api/customer/getSurveyResults'
import { updateSurvey } from '../api/customer/updateSurvey'
import { LinkWithQuery } from '../components/core/LinkWithQuery'
import { PageShell } from '../components/core/PageShell'
import { ModifierSurveyResults } from '../models/modifierSurveyResults'
import { useCustomerStore } from '../store/useCustomerStore'
import { useModifierSurveyStore } from '../store/useModifierSurveryStore'
import { useYearStore } from '../store/useYearStore'
import {
  ModifierTab,
  MODIFIER_SURVEY_TABS,
} from '../utils/enums/modifierSurveyTabs'
import { PATHS } from '../utils/enums/paths'

type ModifierContextTyp = {
  onPrevTab: () => void
  onNextTab: () => void
}

export const ModifierPage = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const surveyResults = useModifierSurveyStore().modifierResults
  const setSurveyYear = useModifierSurveyStore().setYear
  const setSurveyResults = useModifierSurveyStore().setModifierSurveyResults
  const resetSurveyResults = useModifierSurveyStore().resetModifierSurveyResults
  const year = useYearStore().year
  const customer = useCustomerStore().customer

  const [tabs, setTabs] = useState<ModifierTab[]>(MODIFIER_SURVEY_TABS)
  const [lastYearHint, setLastYearHint] = useState(false)
  const [error, setError] = useState<string | undefined>()
  const { t } = useTranslation(['translation', 'modifierSurvey'])

  useEffect(() => {
    setSurveyYear(year!)
    setLastYearHint(false)
  }, [year, setSurveyYear])

  useEffect(() => {
    const fetchSurveyResults = async () => {
      const results = await getSurveyResults(customer!.id, year!).catch(
        async (e) => {
          const resultsLastYear = await getSurveyResults(
            customer!.id!,
            year! - 1
          ).catch((e) => {
            resetSurveyResults()
            return Promise.reject(e)
          })

          setLastYearHint(true)
          return Promise.resolve({
            ...resultsLastYear,
            data: { ...resultsLastYear.data, year: year },
          })
        }
      )

      if (results.status === 200 && results.data) {
        setSurveyResults({ ...results.data, year: year! })
      }
    }

    fetchSurveyResults()
  }, [customer, year, setSurveyResults, resetSurveyResults])

  useEffect(() => {
    if (location) {
      setTabs((prevState: ModifierTab[]) => {
        return prevState.map((tab: ModifierTab) => {
          if (location.pathname === PATHS.MODIFIER + tab.href) {
            return { ...tab, current: true }
          }

          return { ...tab, current: false }
        })
      })
    }
  }, [location])

  useEffect(() => {
    setTabs((prevState: ModifierTab[]) => {
      return prevState.map((tab: ModifierTab) => {
        return { ...tab, selectable: isTabSelectable(tab, surveyResults) }
      })
    })
  }, [surveyResults])

  const isTabSelectable = (
    tab: ModifierTab,
    surveyResults: ModifierSurveyResults
  ) => {
    switch (tab.name) {
      case 'intro.tab':
        return true
      case 'revenue.tab':
        return true
      case 'fuel.tab':
        return (
          surveyResults?.employeeCount !== undefined &&
          surveyResults?.employeeCount > 0 &&
          surveyResults?.revenue !== undefined &&
          surveyResults?.revenue > 0
        )
      case 'heating.tab':
        return surveyResults?.gasAndFuel !== undefined
      case 'renewableEnergyShare.tab':
        return surveyResults?.heatingKind !== undefined
      case 'rent.tab':
        return surveyResults?.renewableEnergyShare !== undefined
      case 'dietKind.tab':
        return surveyResults?.renewableEnergyShare !== undefined
      case 'organicProducts.tab':
        return surveyResults?.dietKind !== undefined
      case 'officeImprovement.tab':
        return surveyResults?.organicProducts !== undefined
      case 'electronics.tab':
        return surveyResults?.officeImprovement !== undefined
      case 'hotels.tab':
        return surveyResults?.electronics !== undefined
      case 'consumption.tab':
        return surveyResults?.hotels !== undefined
      case 'summary.tab':
        return surveyResults?.hotels !== undefined
      default:
        return false
    }
  }

  const onNextTab = () => {
    const index = tabs.findIndex((tab) => tab.current)

    if (index < tabs.length - 1) {
      navigate(`${PATHS.MODIFIER}${tabs[index + 1].href}` + location.search)
    } else {
      if (year) {
        updateSurvey(customer!.id, {
          ...surveyResults,
          year,
        })
          .catch((e: AxiosError<any>) => {
            setError(JSON.stringify(e.response?.data))
            return Promise.reject(e)
          })
          .then((resp) => {
            setSurveyResults(resp.data)
            navigate(PATHS.DASHBOARD + location.search)
          })
      }
    }
  }

  const onPrevTab = () => {
    const index = tabs.findIndex((tab) => tab.current)

    if (index > 0) {
      navigate(`${PATHS.MODIFIER}${tabs[index - 1].href}` + location.search)
    }
  }

  return (
    <PageShell title={t(`modifierSurvey:title`)}>
      <div className="pb-4 overflow-hidden ">
        <div className="hidden sm:block pt-4">
          <nav className="flex flex-wrap " aria-label="Tabs">
            {tabs.map((tab) => (
              <div
                className={classNames(
                  'flex flex-col justify-end pl-2 border-gray-700 mb-4 relative',
                  tab.headline ? 'border-l-2 border-t-2' : ''
                )}
              >
                {tab.headline && (
                  <div className="absolute left-0 top-0 pl-3 whitespace-nowrap pb-4 w-full">
                    <h3 className="font-bold text-sm text-gray-700 bg-gray-50 -top-[10px] relative pl-1 w-full">
                      {t(`modifierSurvey:${tab.headline}`)}
                    </h3>
                  </div>
                )}
                <LinkWithQuery
                  key={tab.name}
                  to={PATHS.MODIFIER + tab.href}
                  className={classNames(
                    tab.current
                      ? 'bg-brand-light text-brand-dark bg-opacity-20'
                      : tab.selectable
                      ? 'text-gray-500 hover:text-gray-700'
                      : 'text-gray-300 cursor-not-allowed',
                    'px-3 py-2 font-medium text-sm rounded-md mr-2 mt-4'
                  )}
                  aria-current={tab.current ? 'page' : undefined}
                >
                  {t(`modifierSurvey:${tab.name}`)}
                </LinkWithQuery>
              </div>
            ))}
          </nav>
        </div>
      </div>

      <div className="overflow-hidden bg-white shadow sm:rounded-lg max-w-4xl">
        <h3 className="text-xl font-medium leading-6 text-gray-900 px-8 pt-8 ">
          {t('modifierSurvey:' + tabs.find((tab) => tab.current)?.title || '')}
        </h3>
        {tabs.find((tab) => tab.current)?.explanation && (
          <p className="text-gray-500 px-8 pt-4 whitespace-pre-wrap">
            {t(
              'modifierSurvey:' +
                tabs.find((tab) => tab.current)?.explanation || ''
            )}
          </p>
        )}
        <Outlet
          context={{
            onPrevTab,
            onNextTab,
          }}
        />
      </div>

      {lastYearHint && (
        <div className="px-8 py-4 bg-brand-light bg-opacity-30 max-w-4xl mt-8 rounded-md flex items-start">
          <InformationCircleIcon className="w-12 text-brand" />
          <div className="pl-4">
            <h3 className="font-bold">
              {t('modifierSurvey:common.lastYearHintTitle')}
            </h3>
            <p className="text-gray-700">
              {t('modifierSurvey:common.lastYearHintText')}
            </p>
          </div>
        </div>
      )}

      {error && (
        <div className="px-8 py-4 bg-red-500 bg-opacity-30 max-w-4xl mt-8 rounded-md flex items-start">
          <ExclamationCircleIcon className="w-12 text-red-500" />
          <div className="pl-4">
            <h3 className="font-bold">Fehler beim Speichern</h3>
            <p className="text-gray-700">
              Beim Speichern der Modifier ist ein Fehler aufgetreten. Bitte
              versuchen Sie es erneut oder kontaktieren Sie uns.
            </p>
            <p className="text-gray-700 pt-2">Details:</p>
            <p className="text-gray-700">{error}</p>
          </div>
        </div>
      )}
    </PageShell>
  )
}

export function useModifierSurveyContext() {
  return useOutletContext<ModifierContextTyp>()
}
