import classNames from 'classnames'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AccountingSoftware } from '../../models/accountingSoftware.enum'
import { Country } from '../../models/country.enum'
import { Industry } from '../../models/industry.enum'
import { Button } from './Button'

export interface ProfileType {
  firstName: string
  lastName: string
  companyName: string
  industry: Industry
  industryOther: string
  accountingSoftware: AccountingSoftware
  accountingSoftwareOther: string
  country: Country
}

export interface ProfileFormProps {
  onSubmit: (profileData: ProfileType) => void
  profileData?: ProfileType
  enableUserChanges?: boolean
}

export const ProfileForm = ({
  profileData,
  onSubmit,
  enableUserChanges,
}: ProfileFormProps) => {
  const { t } = useTranslation([
    'translation',
    'countries',
    'industries',
    'accountingSoftware',
  ])
  const { register, handleSubmit, formState, watch, setValue } =
    useForm<ProfileType>({
      mode: 'onChange',
      defaultValues: {
        country: Country.GERMANY,
      },
    })

  const watchIndustry = watch('industry')
  const watchIndustryOther = watch('industryOther')
  const watchAccountingSoftware = watch('accountingSoftware')
  const watchAccountingSoftwareOther = watch('accountingSoftwareOther')

  useEffect(() => {
    setValue('industryOther', '')
  }, [watchIndustry, setValue])

  useEffect(() => {
    setValue('accountingSoftwareOther', '')
  }, [watchAccountingSoftware, setValue])

  useEffect(() => {
    if (profileData?.firstName) {
      setValue('firstName', profileData.firstName)
    }
    if (profileData?.lastName) {
      setValue('lastName', profileData.lastName)
    }
    if (profileData?.companyName) {
      setValue('companyName', profileData.companyName)
    }
    if (profileData?.industry) {
      setValue('industry', profileData.industry)
    }
    setTimeout(() => {
      if (profileData?.industryOther) {
        setValue('industryOther', profileData.industryOther)
      }
      if (profileData?.accountingSoftwareOther) {
        setValue('accountingSoftwareOther', profileData.accountingSoftwareOther)
      }
    }, 100)
    if (profileData?.accountingSoftware) {
      setValue('accountingSoftware', profileData.accountingSoftware)
    }
  }, [profileData, setValue])

  const submit = async (data: ProfileType) => {
    if (formState.isValid) {
      onSubmit(data)
    }
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="overflow-hidden w-full pl-1 pb-1">
        <div className="bg-white">
          <div className="grid grid-cols-6 gap-6">
            {/** First & Last Name */}
            <div className="col-span-6 grid grid-cols-2 gap-6">
              {/** First  name */}
              <div className="">
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t('onboarding.profileForm.firstName')}
                </label>
                <input
                  id="firstName"
                  {...register('firstName', {
                    required: t('onboarding.profileForm.firstNameHint')!,
                  })}
                  type="text"
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand focus:ring-brand sm:text-sm disabled:bg-gray-50"
                  disabled={!enableUserChanges}
                />
                {formState.errors.firstName && (
                  <p className="text-red-600 bg-red-100 rounded-md px-4 py-1 text-sm mt-1">
                    {formState.errors.firstName.message}
                  </p>
                )}
              </div>

              {/** Last name */}
              <div className="">
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t('onboarding.profileForm.lastName')}
                </label>
                <input
                  id="lastName"
                  {...register('lastName', {
                    required: t('onboarding.profileForm.lastName')!,
                  })}
                  type="text"
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand focus:ring-brand sm:text-sm disabled:bg-gray-50"
                  disabled={!enableUserChanges}
                />
                {formState.errors.lastName && (
                  <p className="text-red-600 bg-red-100 rounded-md px-4 py-1 text-sm mt-1">
                    {formState.errors.lastName.message}
                  </p>
                )}
              </div>
            </div>

            {/** Company name & County*/}
            <div className="col-span-6 grid grid-cols-4 gap-6">
              <div className="col-span-3">
                <label
                  htmlFor="companyName"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t('onboarding.profileForm.companyName')}
                </label>
                <input
                  id="companyName"
                  {...register('companyName', {
                    required: t('onboarding.profileForm.companyNameHint')!,
                  })}
                  type="text"
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand focus:ring-brand sm:text-sm"
                />
                {formState.errors.companyName && (
                  <p className="text-red-600 bg-red-100 rounded-md px-4 py-1 text-sm mt-1">
                    {formState.errors.companyName.message}
                  </p>
                )}
              </div>

              {/** Country */}
              <div className="col-span-1">
                <label
                  htmlFor="country"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t('onboarding.profileForm.country')}
                </label>
                <select
                  id="country"
                  {...register('country', {
                    required: t('onboarding.profileForm.countryHint')!,
                  })}
                  className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-brand focus:outline-none focus:ring-brand sm:text-sm"
                >
                  {Object.keys(Country).map((country: string) => (
                    <option value={country} key={country}>
                      {t(`countries:${country}`)}
                    </option>
                  ))}
                </select>
                {formState.errors.country && (
                  <p className="text-red-600 bg-red-100 rounded-md px-4 py-1 text-sm mt-1">
                    {formState.errors.country.message}
                  </p>
                )}
              </div>
            </div>

            {/** Industry */}
            <div className="col-span-6 grid grid-cols-6 gap-6">
              {/** Industry Select */}
              <div className="col-span-3">
                <label
                  htmlFor="industry"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t('onboarding.profileForm.industry')}
                </label>
                <select
                  id="industry"
                  {...register('industry', {
                    required: t('onboarding.profileForm.industryHint')!,
                  })}
                  className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-brand focus:outline-none focus:ring-brand sm:text-sm"
                >
                  {Object.keys(Industry).map((industry: string) => (
                    <option value={industry} key={industry}>
                      {t(`industries:${industry}`)}
                    </option>
                  ))}
                </select>
                {formState.errors.industry && (
                  <p className="text-red-600 bg-red-100 rounded-md px-4 py-1 text-sm mt-1">
                    {formState.errors.industry.message}
                  </p>
                )}
              </div>

              {/** Industry Input */}
              <div className="col-span-3">
                <label
                  htmlFor="industryOther"
                  className={classNames(
                    'block text-sm font-medium',
                    watchIndustry === Industry.OTHER
                      ? 'text-gray-700'
                      : 'text-gray-300'
                  )}
                >
                  {t('onboarding.profileForm.other')}
                </label>
                <input
                  id="industryOther"
                  {...register('industryOther')}
                  type="text"
                  disabled={watchIndustry !== Industry.OTHER ? true : false}
                  required={watchIndustry === Industry.OTHER ? true : false}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand focus:ring-brand sm:text-sm disabled:bg-gray-100 disabled:cursor-not-allowed disabled:border-gray-200"
                />
              </div>
            </div>

            {/** Accounting Software */}
            <div className="col-span-6 grid grid-cols-6 gap-6">
              {/** Accounting Software Select */}
              <div className="col-span-3">
                <label
                  htmlFor="accountingSoftware"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t('onboarding.profileForm.accountingSoftware')}
                </label>
                <select
                  id="accountingSoftware"
                  {...register('accountingSoftware', {
                    required: t(
                      'onboarding.profileForm.accountingSoftwareHint'
                    )!,
                  })}
                  className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-brand focus:outline-none focus:ring-brand sm:text-sm"
                >
                  {Object.keys(AccountingSoftware).map((software: string) => (
                    <option value={software} key={software}>
                      {t(`accountingSoftware:${software}`)}
                    </option>
                  ))}
                </select>
                {formState.errors.accountingSoftware && (
                  <p className="text-red-600 bg-red-100 rounded-md px-4 py-1 text-sm mt-1">
                    {formState.errors.accountingSoftware.message}
                  </p>
                )}
              </div>

              {/** Accounting Software Input */}
              <div className="col-span-3">
                <label
                  htmlFor="accountingSoftwareOther"
                  className={classNames(
                    'block text-sm font-medium',
                    watchAccountingSoftware === AccountingSoftware.OTHER
                      ? 'text-gray-700'
                      : 'text-gray-300'
                  )}
                >
                  {t('onboarding.profileForm.other')}
                </label>
                <input
                  id="accountingSoftwareOther"
                  {...register('accountingSoftwareOther')}
                  disabled={
                    watchAccountingSoftware !== AccountingSoftware.OTHER
                      ? true
                      : false
                  }
                  type="text"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand focus:ring-brand sm:text-sm disabled:bg-gray-100 disabled:cursor-not-allowed disabled:border-gray-200"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="pt-6 text-left">
          <Button
            label={t('onboarding.profileForm.button')}
            disabled={
              !formState.isValid ||
              (watchAccountingSoftware === AccountingSoftware.OTHER &&
                !watchAccountingSoftwareOther) ||
              (watchIndustry === Industry.OTHER && !watchIndustryOther)
            }
            type="submit"
          />
        </div>
      </div>
    </form>
  )
}
