import axios from 'axios'

export const initAxios = (token?: string) => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL
  axios.interceptors.request.use(async (config) => {
    // Force token refresh to always use the correct one (with email_verified true)
    if (token && config.headers) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
  })
}
