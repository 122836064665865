import { ArrowRightIcon } from '@heroicons/react/24/outline'
import { ColDef, ValueFormatterParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getAccountingData } from '../api/customer/getAccountingData'
import { updateAccountingData } from '../api/customer/updateAccountingData'
import { Button } from '../components/core/Button'
import { LoadingSpinner } from '../components/core/LoadingSpinner'
import { PageShell } from '../components/core/PageShell'
import {
  ParsingError,
  ParsingErrorCode,
  ParsingErrorModal,
} from '../components/transactions/ParsingErrorModal'
import { UploadTransactionsModal } from '../components/transactions/UploadTransactionsModal'
import { AccountingData } from '../models/accountingData'
import { useCustomerStore } from '../store/useCustomerStore'
import { useYearStore } from '../store/useYearStore'
import useLocalStorage from '../utils/useLocalStorage'

dayjs.extend(customParseFormat)

const transactionColumns = [
  {
    key: 'date',
    title: 'dataAssignment.dateColumnTitle',
    hint: 'dataAssignment.dateColumnHint',
    csvColumnName: undefined,
    isOptional: false,
  },
  {
    key: 'amount',
    title: 'dataAssignment.amountColumnTitle',
    hint: 'dataAssignment.amountColumnHint',
    csvColumnName: undefined,
    isOptional: false,
  },
  {
    key: 'amountNet',
    title: 'dataAssignment.amountNetTitle',
    hint: 'dataAssignment.amountNetHint',
    csvColumnName: undefined,
    isOptional: true,
  },
  {
    key: 'tax',
    title: 'dataAssignment.taxTitle',
    hint: 'dataAssignment.taxHint',
    csvColumnName: undefined,
    isOptional: true,
  },
  {
    key: 'currency',
    title: 'dataAssignment.currencyTitle',
    hint: 'dataAssignment.currencyHint',
    csvColumnName: undefined,
    isOptional: true,
  },
  {
    key: 'country',
    title: 'dataAssignment.countryTitle',
    hint: 'dataAssignment.countryHint',
    csvColumnName: undefined,
    isOptional: true,
  },
  {
    key: 'supplier',
    title: 'dataAssignment.supplierColumnTitle',
    hint: 'dataAssignment.supplierColumnHint',
    csvColumnName: undefined,
    isOptional: false,
  },
  {
    key: 'description',
    title: 'dataAssignment.descriptionColumnTitle',
    hint: 'dataAssignment.descriptionColumnHint',
    csvColumnName: undefined,
    isOptional: false,
  },
  {
    key: 'account',
    title: 'dataAssignment.accountColumnTitle',
    hint: 'dataAssignment.accountColumnHint',
    csvColumnName: undefined,
    isOptional: false,
  },
  {
    key: 'postingText',
    title: 'dataAssignment.postingTextColumnTitle',
    hint: 'dataAssignment.postingTextColumnHint',
    csvColumnName: undefined,
    isOptional: true,
  },
]

export const TransactionsPage = () => {
  const gridApiRef = useRef<AgGridReactType>(null)

  const year = useYearStore((state) => state.year)

  const customer = useCustomerStore((state) => state.customer)
  const defaultColDef = {
    sortable: true,
    editable: false,
    resizable: true,
  }

  const { t } = useTranslation(['translation', 'transactions'])
  const [loading, setLoading] = useState(true)
  const [modalOpen, setModalOpen] = useState(false)
  const [errorModalOpen, setErrorModalOpen] = useState(false)
  const [parsingErrors, setParsingErrors] = useState<ParsingError[]>([])

  const [rowData, setRowData] = useState<any[]>([])
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([])

  const [dateKey, setDateKey] = useLocalStorage(transactionColumns[0].key, '')
  const [amountKey, setAmountKey] = useLocalStorage(
    transactionColumns[1].key,
    ''
  )
  const [amountNetKey, setAmountNetKey] = useLocalStorage(
    transactionColumns[2].key,
    ''
  )
  const [taxKey, setTaxKey] = useLocalStorage(transactionColumns[3].key, '')
  const [currencyKey, setCurrencyKey] = useLocalStorage(
    transactionColumns[4].key,
    ''
  )
  const [countryKey, setCountryKey] = useLocalStorage(
    transactionColumns[5].key,
    ''
  )
  const [supplierKey, setSupplierKey] = useLocalStorage(
    transactionColumns[6].key,
    ''
  )
  const [descriptionKey, setDescriptionKey] = useLocalStorage(
    transactionColumns[7].key,
    ''
  )
  const [accountKey, setAccountKey] = useLocalStorage(
    transactionColumns[8].key,
    ''
  )
  const [postingTextKey, setPostingTextKey] = useLocalStorage(
    transactionColumns[9].key,
    ''
  )

  const [showDataConfig, setShowDataConfig] = useState(true)

  const updateTableWithAccountingData = useCallback(
    (data: AccountingData[]) => {
      const colDefs = [
        {
          field: 'account',
          headerName: t('transactions:dataAssignment.accountColumnTitle'),
          filter: 'agTextColumnFilter',
        },
        {
          field: 'date',
          headerName: t('transactions:dataAssignment.dateColumnTitle'),
          filter: 'agDateColumnFilter',
          filterParams: {
            comparator: function (
              filterLocalDateAtMidnight: any,
              cellValue: any
            ) {
              //using moment js
              const dateAsString = dayjs(cellValue).format('DD/MM/YYYY')
              const dateParts = dateAsString.split('/')
              const cellDate = new Date(
                Number(dateParts[2]),
                Number(dateParts[1]) - 1,
                Number(dateParts[0])
              )

              if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0
              }

              if (cellDate < filterLocalDateAtMidnight) {
                return -1
              }

              if (cellDate > filterLocalDateAtMidnight) {
                return 1
              }
            },
          },
          valueFormatter: (params: ValueFormatterParams) => {
            return dayjs(params.data.date).format('DD.MM.YYYY')
          },
          sort: 'desc' as any,
        },
        {
          field: 'amount',
          headerName: t('transactions:dataAssignment.amountColumnTitle'),
          filter: 'agNumberColumnFilter',
        },
        {
          field: 'amountNet',
          headerName: t('transactions:dataAssignment.amountNetTitle'),
          filter: 'agNumberColumnFilter',
        },
        {
          field: 'currency',
          headerName: t('transactions:dataAssignment.currencyTitle'),
          filter: 'agTextColumnFilter',
        },
        {
          field: 'tax',
          headerName: t('transactions:dataAssignment.taxTitle'),
          filter: 'agNumberColumnFilter',
        },
        {
          field: 'country',
          headerName: t('transactions:dataAssignment.countryTitle'),
          filter: 'agTextColumnFilter',
        },
        {
          field: 'supplier',
          headerName: t('transactions:dataAssignment.supplierColumnTitle'),
          filter: 'agTextColumnFilter',
        },
        {
          field: 'description',
          headerName: t('transactions:dataAssignment.descriptionColumnTitle'),
          filter: 'agTextColumnFilter',
        },
        {
          field: 'postingText',
          headerName: t('transactions:dataAssignment.postingTextColumnTitle'),
          filter: 'agTextColumnFilter',
        },
      ]

      setShowDataConfig(false)
      setColumnDefs(colDefs)
      setRowData(data)
      setLoading(false)
    },
    [t]
  )

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      const resp = await getAccountingData(customer!.id)

      if (resp.status === 201 || resp.status === 200) {
        updateTableWithAccountingData(
          resp.data.filter(
            (row: any) => new Date(row.date).getFullYear() === year
          )
        )
      }
    }

    fetchData()
  }, [customer, year, updateTableWithAccountingData])

  const fetchData = async () => {
    const resp = await getAccountingData(customer!.id)

    if (resp.status === 201 || resp.status === 200) {
      updateTableWithAccountingData(
        resp.data.filter(
          (row: any) => new Date(row.date).getFullYear() === year
        )
      )
    }
  }

  const onFileUploaded = (rowData: any[], colDefs: ColDef[]) => {
    setShowDataConfig(true)
    setRowData(rowData)
    setColumnDefs(colDefs)
  }

  const onSelectionChange = (e: ChangeEvent<HTMLSelectElement>, col: any) => {
    switch (col) {
      case 'date':
        setDateKey(e.target.value)
        return
      case 'supplier':
        setSupplierKey(e.target.value)
        return
      case 'amount':
        setAmountKey(e.target.value)
        return
      case 'amountNet':
        setAmountNetKey(e.target.value)
        return
      case 'tax':
        setTaxKey(e.target.value)
        return
      case 'currency':
        setCurrencyKey(e.target.value)
        return
      case 'country':
        setCountryKey(e.target.value)
        return
      case 'account':
        setAccountKey(e.target.value)
        return
      case 'postingText':
        setPostingTextKey(e.target.value)
        return
      case 'description':
        setDescriptionKey(e.target.value)
        return
    }
  }

  const onUploadData = async () => {
    const cleanedRows = rowData.filter((row) => row[dateKey] !== undefined)

    const accountingData = cleanedRows.map((row) => {
      const cleanDate = row[dateKey].match(/\d|\.|-/g).join('')
      const date = dayjs(cleanDate, ['DD.MM.YYYY', 'DD.MM.YY'], true)

      if (!date.isValid()) {
        setParsingErrors((prev) => [
          ...prev,
          {
            code: ParsingErrorCode.DATE_PARSING_ERROR,
            data: row[dateKey],
          },
        ])
        return undefined
      }

      return {
        date: date.toISOString(),
        supplier: row[supplierKey] || '',
        description: row[descriptionKey] || '',
        ...(amountKey !== '' && row[amountKey]
          ? { amount: parseNumber(row[amountKey]) }
          : {}),
        ...(amountNetKey !== '' && row[amountNetKey]
          ? { amountNet: parseNumber(row[amountNetKey]) }
          : {}),
        ...(taxKey !== '' && row[taxKey]
          ? { tax: parseNumber(row[taxKey]) }
          : {}),
        ...(currencyKey !== '' && row[currencyKey]
          ? { currency: row[currencyKey] }
          : {}),
        ...(countryKey !== '' && row[countryKey]
          ? { country: row[countryKey] }
          : {}),
        account: row[accountKey] || '',
        postingText: row[postingTextKey] || '',
      } as AccountingData
    })

    if (accountingData.includes(undefined)) {
      setErrorModalOpen(true)

      return
    }

    setLoading(true)
    try {
      const resp = await updateAccountingData(
        customer!.id,
        accountingData as AccountingData[]
      )

      if (resp.status === 200 || resp.status === 201) {
        fetchData()
      }
    } catch (err: any) {
      setLoading(false)
      if (err?.response.data.details) {
        console.log(err.response.data.details[0])
        setParsingErrors([
          { code: '' as ParsingErrorCode, data: err.response.data.details[0] },
        ])
      }
      setErrorModalOpen(true)
    }
  }

  const parseNumber = (str: string) => {
    const hasComma = str.indexOf(',') !== -1

    const germanRegex = new RegExp(
      /^-?\d{1,3}(?:\.\d{3})*(?:\d+)?(?:,\d{1,2})?$/
    )
    if (germanRegex.test(str)) {
      return parseFloat(str.replace(/\./g, '').replace(',', '.'))
    } else if (hasComma) {
      return parseFloat(str.replace(/,/g, ''))
    } else {
      return parseFloat(str)
    }
  }

  const onExportAsCsv = () => {
    gridApiRef.current!.api.exportDataAsCsv()
  }

  if (loading) {
    return (
      <PageShell title={t('transactions:title')}>
        <LoadingSpinner />
      </PageShell>
    )
  }

  return (
    <PageShell
      title={t('transactions:title')}
      hint={`${t('transactions:hint1')}

        ${t('transactions:hint2')}`}
      headerActionComponent={
        rowData?.length > 0 && showDataConfig ? (
          <></>
        ) : (
          <Button
            label={t('transactions:button')}
            onClick={() => setModalOpen(true)}
          />
        )
      }
    >
      <ParsingErrorModal
        isOpen={errorModalOpen}
        onClose={() => setErrorModalOpen(false)}
        errors={parsingErrors}
      />

      <UploadTransactionsModal
        isOpen={modalOpen}
        afterFileUpload={onFileUploaded}
        onClose={() => setModalOpen(false)}
        hasAlreadyAcceptedHint={rowData.length > 0}
      />

      <div className="w-full h-full">
        {showDataConfig && (
          <div>
            <div className="w-full flex flex-row justify-between items-center bg-white px-4 py-4 rounded-md">
              <div className="w-2/3">
                <h4 className="text-lg font-bold">
                  {t('transactions:dataAssignment.title')}
                </h4>
                <p className="text-gray-700 py-2">
                  {t('transactions:dataAssignment.text')}
                </p>
              </div>
              <div className="w-1/3 flex justify-end">
                <div className="mr-4 inline">
                  <Button
                    label={t('transactions:cancelBtn')}
                    onClick={() => {
                      setShowDataConfig(false)
                      setRowData([])
                    }}
                    variant="secondary"
                  ></Button>
                </div>
                <Button
                  label={t('transactions:uploadBtn')}
                  disabled={
                    !dateKey ||
                    !supplierKey ||
                    !descriptionKey ||
                    !accountKey ||
                    !amountKey ||
                    amountKey === '-' ||
                    rowData.every((row) => {
                      return (
                        row[dateKey] === undefined ||
                        row[supplierKey] === undefined ||
                        row[descriptionKey] === undefined ||
                        row[accountKey] === undefined
                      )
                    })
                  }
                  onClick={onUploadData}
                />
              </div>
            </div>
            <div className="flex overflow-y-hidden overflow-x-scroll gap-4 pt-4">
              {transactionColumns.map((transactionColumn) => (
                <div
                  className="bg-white min-w-[250px] w-[250px] rounded-md shadow-sm px-4 py-8 flex justify-between flex-col"
                  key={transactionColumn.key}
                >
                  <div>
                    <h4 className="text-lg font-bold">
                      {t(`transactions:${transactionColumn.title}`)}
                      {transactionColumn.isOptional ? ' (Optional)' : ''}
                    </h4>
                    <p className="text-gray-700 py-2">
                      {t(`transactions:${transactionColumn.hint}`)}
                    </p>
                  </div>
                  <div>
                    <select
                      id="location"
                      name="location"
                      className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-brand focus:outline-none focus:ring-brand sm:text-sm"
                      defaultValue={
                        JSON.parse(
                          localStorage.getItem(transactionColumn.key) as string
                        ) || undefined
                      }
                      onChange={(e) =>
                        onSelectionChange(e, transactionColumn.key)
                      }
                    >
                      <option value={undefined}>-</option>
                      {columnDefs.map((colDef: ColDef) => (
                        <option value={colDef.field} key={colDef.field}>
                          {colDef.field}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {rowData?.length > 0 ? (
          <>
            <div
              className="ag-theme-alpine mt-4 bg-white shadow-sms rounded-md py-4 px-4 h-full"
              style={{ width: '100%', height: showDataConfig ? '60%' : '90%' }}
            >
              <AgGridReact
                ref={gridApiRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                onGridReady={(e) => {
                  e.api.sizeColumnsToFit({ defaultMinWidth: 200 })
                }}
              />
            </div>

            {!showDataConfig && (
              <div className="py-2 bg-gray-100 flex justify-end px-8 rounded-bl-md rounded-br-md">
                <Button
                  label="Transaktionen als CSV downloaden"
                  onClick={() => onExportAsCsv()}
                />
              </div>
            )}
          </>
        ) : (
          <div className="text-center pt-16">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                vectorEffect="non-scaling-stroke"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
              />
            </svg>
            <h3 className="mt-2 text-sm font-medium text-gray-900">
              {t('dashboard.placeholder.title')}
            </h3>
            <p className="mt-1 text-sm text-gray-500 max-w-sm mx-auto">
              {t('dashboard.placeholder.transactionPageText')}
            </p>
            <div className="mt-6">
              <button
                type="button"
                onClick={() => setModalOpen(true)}
                className="inline-flex items-center rounded-md border border-transparent bg-brand px-4 py-2 text-sm font-medium text-white shadow-sm hover:brand-dark focus:outline-none focus:ring-2 focus:ring-brand focus:ring-offset-2"
              >
                {t('dashboard.placeholder.transactionPageButton')}
                <ArrowRightIcon
                  className="-mr-1 ml-2 h-5 w-5"
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
        )}
      </div>
    </PageShell>
  )
}
