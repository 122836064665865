import { useTranslation } from 'react-i18next'
import { useModifierSurveyContext } from '../../pages/Modifier'
import { useModifierSurveyStore } from '../../store/useModifierSurveryStore'
import { Button } from '../core/Button'

export const SurveyQuestionConsumption = () => {
  const { onPrevTab, onNextTab } = useModifierSurveyContext()
  const { t } = useTranslation(['modifierSurvey'])
  const otherText = useModifierSurveyStore(
    (state) => state.modifierResults?.otherText
  )
  const setOtherText = useModifierSurveyStore((state) => state.setOtherText)

  return (
    <>
      <ul className="mt-1 max-w-2xl text-gray-500 text-md list-outside pl-14 pt-4">
        <li className="list-disc">{t('modifierSurvey:consumption.hint1')}</li>
        <li className="list-disc pt-2">
          {t('modifierSurvey:consumption.hint2')}
        </li>
        <li className="list-disc pt-2">
          {t('modifierSurvey:consumption.hint3')}
          <ul className="pl-8">
            <li className="list-disc pt-2">
              {t('modifierSurvey:consumption.hint31')}
            </li>{' '}
            <li className="list-disc pt-2">
              {t('modifierSurvey:consumption.hint32')}
            </li>{' '}
            <li className="list-disc pt-2">
              {t('modifierSurvey:consumption.hint33')}
            </li>
          </ul>
        </li>
        <li className="list-disc pt-2">
          {t('modifierSurvey:consumption.hint4')}
        </li>
        <li className="list-disc pt-2">
          {t('modifierSurvey:consumption.hint5')}
        </li>
      </ul>
      <div className="flex flex-col w-full items-start justify-start col-span-5 pt-8 px-8 py-16">
        <textarea
          rows={4}
          name="comment"
          id="comment"
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand focus:ring-brand sm:text-sm"
          defaultValue={otherText}
          onBlur={(e) => setOtherText(e.target.value)}
        />
      </div>
      <div className="bg-gray-100 py-4 flex items-center justify-between w-full px-8">
        <Button
          onClick={() => onPrevTab()}
          label={t('modifierSurvey:common.backBtn')}
          variant="secondary"
        />
        <Button
          onClick={() => onNextTab()}
          label={t('modifierSurvey:common.nextBtn')}
          variant="primary"
        />
      </div>
    </>
  )
}
