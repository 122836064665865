import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { auth } from '../firebase.config'
import { useCustomerStore } from '../store/useCustomerStore'
import { useUserStore } from '../store/useUserStore'
import { PATHS } from '../utils/enums/paths'

export const LogoutPage = () => {
  const { t } = useTranslation()
  const resetUser = useUserStore((state) => state.resetUser)
  const resetCustomer = useCustomerStore((state) => state.resetCustomer)
  const navigate = useNavigate()

  const handleLogout = async () => {
    try {
      auth.signOut().then(() => {
        resetUser()
        resetCustomer()
        axios.interceptors.request.clear()

        navigate(PATHS.LOGIN, { replace: true })
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error(error.message)
    }
  }

  handleLogout()

  return (
    <div className="h-full w-full flex justify-center items-center">
      <div className="max-w-lg mx-auto flex items-center flex-col">
        <h1 className="text-center text-2xl font-bold">
          {t('common.logout.title')}
        </h1>
        <p className="text-center pt-4">{t('logout.text')}</p>
        <button className="mt-8 text-brand" onClick={() => handleLogout()}>
          {t('common.logout.link')} &rarr;
        </button>
      </div>
    </div>
  )
}
