import axios from 'axios'
import { ModifierSurveyResults } from '../../models/modifierSurveyResults'
import { API_ROUTES } from '../routes'

export const updateSurvey = async (
  customerId: string,
  surveyResults: ModifierSurveyResults
) => {
  return axios.post(API_ROUTES.CUSTOMER + `${customerId}/survey`, {
    year: surveyResults.year,
    employeeCount: surveyResults.employeeCount,
    revenue: surveyResults.revenue,
    dietKind: surveyResults.dietKind,
    ...(surveyResults.dietKindText
      ? { dietKindText: surveyResults.dietKindText }
      : {}),
    electronics: surveyResults.electronics,
    ...(surveyResults.electronicsText
      ? { electronicsText: surveyResults.electronicsText }
      : {}),
    gasAndFuel: surveyResults.gasAndFuel,
    ...(surveyResults.gasAndFuelText
      ? { gasAndFuelText: surveyResults.gasAndFuelText }
      : {}),
    heatingKind: surveyResults.heatingKind,
    ...(surveyResults.heatingKindText
      ? { heatingKindText: surveyResults.heatingKindText }
      : {}),
    hotels: surveyResults.hotels,
    ...(surveyResults.hotelsText
      ? { hotelsText: surveyResults.hotelsText }
      : {}),
    officeImprovement: surveyResults.officeImprovement,
    ...(surveyResults.officeImprovementText
      ? { officeImprovementText: surveyResults.officeImprovementText }
      : {}),
    organicProducts: surveyResults.organicProducts,
    ...(surveyResults.organicProductsText
      ? { organicProductsText: surveyResults.organicProductsText }
      : {}),
    renewableEnergyShare: surveyResults.renewableEnergyShare,
    ...(surveyResults.renewableEnergyShareText
      ? { renewableEnergyShareText: surveyResults.renewableEnergyShareText }
      : {}),
    ...(surveyResults.otherText ? { otherText: surveyResults.otherText } : {}),
    ...(surveyResults.electricityShare || surveyResults.heatingShare
      ? {
          electricityShare: surveyResults.electricityShare || 0,
          heatingShare: surveyResults.heatingShare || 0,
        }
      : {}),
    ...(surveyResults.heatingKwh || surveyResults.electricityKwh
      ? {
          heatingKwh: surveyResults.heatingKwh || 0,
          electricityKwh: surveyResults.electricityKwh || 0,
        }
      : {}),
    ...(surveyResults.officeSizeQm
      ? { officeSizeQm: surveyResults.officeSizeQm }
      : {}),
    ...(surveyResults.electricityHeatingShareText
      ? {
          electricityHeatingShareText:
            surveyResults.electricityHeatingShareText,
        }
      : {}),
  })
}
