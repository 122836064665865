import create from 'zustand'

interface YearStore {
  year: number | undefined
  setYear: (newYear: number) => void
}
export const useYearStore = create<YearStore>((set) => ({
  year: undefined,
  setYear: (newYear: number) => set(() => ({ year: newYear })),
}))
