import create from 'zustand'
import { devtools } from 'zustand/middleware'
import { User } from '../models/user'

interface UserStore {
  user: User | undefined
  setUser: (newUser: User) => void
  resetUser: () => void
}
export const useUserStore = create<UserStore>(
  devtools((set) => ({
    user: undefined,
    setUser: (newUser: User) => set(() => ({ user: newUser })),
    resetUser: () => set(() => ({ user: undefined })),
  })) as any
)
