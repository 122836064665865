import { useTranslation } from 'react-i18next'

export const LanguageSwitch = () => {
  const { i18n, t } = useTranslation()

  const lngs = {
    en: { nativeName: 'English' },
    de: { nativeName: 'Deutsch' },
  }

  return (
    <div className="w-full">
      <label htmlFor="year" className="block text-sm font-medium text-gray-700">
        {t('common.language')}
      </label>
      <select
        id="language"
        name="language"
        className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-brand focus:outline-none focus:ring-brand sm:text-sm"
        defaultValue={i18n.resolvedLanguage || 'en'}
        onChange={(event) =>
          i18n.changeLanguage(event.target.value, () => {
            window.location.reload()
          })
        }
      >
        <option value="en">{lngs['en'].nativeName}</option>
        <option value="de">{lngs['de'].nativeName}</option>
      </select>
    </div>
  )
}
