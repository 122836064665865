import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import React, { Suspense } from 'react'
import { Tooltip } from 'react-tooltip'
import { useYearStore } from '../../store/useYearStore'
import { LoadingSpinner } from './LoadingSpinner'

export interface PageShellProps {
  title: string
  headerActionComponent?: React.ReactElement
  hint?: string
}

export const PageShell = (props: React.PropsWithChildren<PageShellProps>) => {
  const year = useYearStore((state) => state.year)

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <div className="bg-gray-50 h-full w-full px-8 p-8 overflow-auto flex flex-col">
        <div className="flex items-center justify-between pb-2">
          <div className="flex items-center">
            <h1 className="text-3xl text-bold text-gray-700">{`${props.title}`}</h1>{' '}
            {props.hint && (
              <>
                <Tooltip
                  anchorId="hint-icon-page"
                  place="bottom"
                  className="w-60 whitespace-pre-line z-50"
                />

                <div
                  className="w-5 h-5 ml-2 pt-0.5 text-gray-500"
                  data-tooltip-content={props.hint}
                  id="hint-icon-page"
                >
                  <QuestionMarkCircleIcon />
                </div>
              </>
            )}
            <span className="bg-gray-200 px-2 py-1 rounded-full text-xs text-bold ml-4">
              {year}
            </span>
          </div>
          {props.headerActionComponent}
        </div>
        <hr className="mb-4" />
        <div className="h-full">{props.children}</div>
      </div>
    </Suspense>
  )
}
