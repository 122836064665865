import React from 'react'
import { Link, useLocation } from 'react-router-dom'

interface LinkWithQueryProps {
  to: string
  className?: string
}

export const LinkWithQuery = ({
  children,
  to,
  ...props
}: React.PropsWithChildren<LinkWithQueryProps>) => {
  const { search } = useLocation()

  return (
    <Link to={to + search} {...props}>
      {children}
    </Link>
  )
}
