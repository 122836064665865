import { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { useSearchParams } from 'react-router-dom'
import termsPath from '../md/AGB.md'

export const TermsPage = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams()
  const [termsMd, setTermsMd] = useState<any>({ terms: null })

  useEffect(() => {
    setSearchParams(undefined)
  }, [setSearchParams])

  useEffect(() => {
    fetch(termsPath)
      .then((response) => response.text())
      .then((terms) => {
        setTermsMd({ terms })
      })
  })

  return (
    <div className="prose mx-auto py-16">
      <ReactMarkdown children={termsMd.terms} components={{}}></ReactMarkdown>
    </div>
  )
}
