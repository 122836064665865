import classNames from 'classnames'

interface ButtonProps {
  label: string
  type?: 'submit' | 'reset' | 'button'
  disabled?: boolean
  variant?: 'primary' | 'secondary'
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const Button = ({
  label,
  onClick,
  type = 'button',
  disabled = false,
  variant = 'primary',
}: ButtonProps) => {
  return (
    <button
      type={type}
      disabled={disabled}
      className={classNames(
        variant === 'primary' ? 'bg-brand text-white hover:bg-brand-light' : '',
        variant === 'secondary'
          ? 'bg-gray-200 text-gray-800 hover:bg-gray-300'
          : '',
        'inline-flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium shadow-sm  focus:outline-none focus:ring-2 focus:ring-brand focus:ring-offset-2 disabled:bg-gray-200 disabled:text-gray-400'
      )}
      {...(onClick ? { onClick } : {})}
    >
      {label}
    </button>
  )
}
