import { useTranslation } from 'react-i18next'
import { useModifierSurveyContext } from '../../pages/Modifier'
import { Button } from '../core/Button'

export const SurveyQuestionIntro = () => {
  const { t } = useTranslation(['modifierSurvey'])
  const { onNextTab } = useModifierSurveyContext()

  return (
    <>
      <div className="pt-8 w-full px-8 py-16">
        <span className="text-gray-700">
          {t('modifierSurvey:intro.hintTitle')}
        </span>
        <ul className="mt-1 max-w-2xl text-gray-700 text-md list-outside pl-4">
          <li className="list-disc">{t('modifierSurvey:intro.hint1')}</li>
          <li className="list-disc pt-2">{t('modifierSurvey:intro.hint2')}</li>
          <li className="list-disc pt-2">{t('modifierSurvey:intro.hint3')}</li>
        </ul>
      </div>
      <div className="bg-gray-100 py-4 flex items-center justify-end w-full px-8">
        <Button
          onClick={() => onNextTab()}
          label={t('modifierSurvey:common.nextBtn')}
          variant="primary"
        />
      </div>
    </>
  )
}
