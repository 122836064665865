import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useModifierSurveyContext } from '../../pages/Modifier'
import { useModifierSurveyStore } from '../../store/useModifierSurveryStore'
import { Electronics } from '../../utils/enums/modifierSurveyEnums'
import { Button } from '../core/Button'
import { QuestionWidget } from './QuestionWidget'

export interface SurveyQuestionElectronicsProps {
  editable: boolean
}

export const SurveyQuestionElectronics = ({
  editable,
}: SurveyQuestionElectronicsProps) => {
  const context = useModifierSurveyContext()
  const { t } = useTranslation(['modifierSurvey'])
  const options = Object.keys(Electronics).map((value: string) => ({
    name: t(`modifierSurvey:electronics.${value}`),
    value,
  }))
  const [selectedValue, setSelectedValue] = useState<Electronics | undefined>(
    undefined
  )

  const electronics = useModifierSurveyStore().modifierResults?.electronics
  const electronicsText =
    useModifierSurveyStore().modifierResults?.electronicsText
  const setElectronics = useModifierSurveyStore().setElectronics
  const setElectronicsText = useModifierSurveyStore().setElectronicsText

  useEffect(() => {
    setSelectedValue(electronics)
  }, [electronics])

  const onSelect = (selectedValue: string) => {
    setElectronics(selectedValue as Electronics)
  }

  const onCommentInput = (comment: string) => {
    setElectronicsText(comment)
  }

  return (
    <>
      <div
        className={classNames(editable ? 'pt-8 w-full px-8 py-16' : 'w-full')}
      >
        <QuestionWidget
          options={options}
          selectedValue={selectedValue}
          onSelect={onSelect}
          onCommentInput={onCommentInput}
          note={electronicsText}
          editable={editable}
        />
      </div>
      {editable && (
        <div className="bg-gray-100 py-4 flex items-center justify-between w-full px-8">
          <Button
            onClick={() => context?.onPrevTab()}
            label={t('modifierSurvey:common.backBtn')}
            variant="secondary"
          />
          <Button
            onClick={() => context?.onNextTab()}
            label={t('modifierSurvey:common.nextBtn')}
            variant="primary"
            disabled={selectedValue === undefined}
          />
        </div>
      )}
    </>
  )
}
