import create from 'zustand'
import { devtools } from 'zustand/middleware'
import { ModifierSurveyResults } from '../models/modifierSurveyResults'
import {
  DietKind,
  Electronics,
  GasAndFuel,
  HeatingKind,
  Hotels,
  OfficeImprovement,
  OrganicProducts,
  RenewableEnergyShare,
} from '../utils/enums/modifierSurveyEnums'

interface ModifierSurveyStore {
  modifierResults: ModifierSurveyResults
  setYear: (year: number) => void
  setEmployeeCount: (employeeCount: number) => void
  setRevenue: (revenue: number) => void
  setGasAndFuel: (gasAndFuel: GasAndFuel) => void
  setGasAndFuelText: (gasAndFuelText: string) => void
  setHeatingKind: (heatingKind: HeatingKind) => void
  setHeatingKindText: (heatingKindText: string) => void
  setRenewableEnergyShare: (renewableEnergyShare: RenewableEnergyShare) => void
  setRenewableEnergyShareText: (renewableEnergyShareText: string) => void
  setDietKind: (dietKind: DietKind) => void
  setDietKindText: (dietKindText: string) => void
  setOrganicProducts: (organicProducts: OrganicProducts) => void
  setOrganicProductsText: (organicProductsText: string) => void
  setOfficeImprovement: (officeImprovement: OfficeImprovement) => void
  setOfficeImprovementText: (officeImprovementText: string) => void
  setElectronics: (electronics: Electronics) => void
  setElectronicsText: (electronicsText: string) => void
  setHotels: (hotels: Hotels) => void
  setHotelsText: (hotelsText: string) => void
  setOtherText: (otherTexrt: string) => void
  setElectricityShare: (electricityShare: number) => void
  setHeatingShare: (heatingShare: number) => void
  setElectricityKwh: (electricityKwh: number) => void
  setHeatingKwh: (heatingKwh: number) => void
  setOfficeSizeQm: (officeSizeQm: number) => void
  setElectricityHeatingShareText: (electricityHeatingShareText: string) => void
  resetWarmRent: () => void
  resetExactUsage: () => void
  resetGuess: () => void
  resetModifierSurveyResults: () => void
  setModifierSurveyResults: (
    modifierSurveyResults: ModifierSurveyResults
  ) => void
}
export const useModifierSurveyStore = create<ModifierSurveyStore>(
  devtools((set) => ({
    modifierResults: undefined,
    setYear: (year: number) =>
      set(
        (prev: ModifierSurveyStore) => ({
          modifierResults: {
            ...prev.modifierResults,
            year,
          },
        }),
        false,
        { type: 'modifierSurvey/setYear', year }
      ),
    setGasAndFuel: (gasAndFuel: GasAndFuel) =>
      set(
        (prev: ModifierSurveyStore) => ({
          modifierResults: {
            ...prev.modifierResults,
            gasAndFuel,
          },
        }),
        false,
        { type: 'modifierSurvey/setGasAndFuel', gasAndFuel }
      ),
    setGasAndFuelText: (gasAndFuelText: string | undefined) =>
      set(
        (prev: ModifierSurveyStore) => ({
          modifierResults: {
            ...prev.modifierResults,
            gasAndFuelText,
          },
        }),
        false,
        { type: 'modifierSurvey/setGasAndFuelText', gasAndFuelText }
      ),
    setHeatingKind: (heatingKind: HeatingKind) =>
      set(
        (prev: ModifierSurveyStore) => ({
          modifierResults: {
            ...prev.modifierResults,
            heatingKind,
          },
        }),
        false,
        { type: 'modifierSurvey/setHeatingKind', heatingKind }
      ),
    setHeatingKindText: (heatingKindText: string | undefined) =>
      set(
        (prev: ModifierSurveyStore) => ({
          modifierResults: {
            ...prev.modifierResults,
            heatingKindText,
          },
        }),
        false,
        { type: 'modifierSurvey/setHeatingKindText', heatingKindText }
      ),
    setRenewableEnergyShare: (renewableEnergyShare: RenewableEnergyShare) =>
      set(
        (prev: ModifierSurveyStore) => ({
          modifierResults: {
            ...prev.modifierResults,
            renewableEnergyShare,
          },
        }),
        false,
        { type: 'modifierSurvey/setRenewableEnergyShare', renewableEnergyShare }
      ),
    setRenewableEnergyShareText: (
      renewableEnergyShareText: string | undefined
    ) =>
      set(
        (prev: ModifierSurveyStore) => ({
          modifierResults: {
            ...prev.modifierResults,
            renewableEnergyShareText,
          },
        }),
        false,
        {
          type: 'modifierSurvey/setRenewableEnergyShareText',
          renewableEnergyShareText,
        }
      ),
    setDietKind: (dietKind: DietKind) =>
      set(
        (prev: ModifierSurveyStore) => ({
          modifierResults: { ...prev.modifierResults, dietKind },
        }),
        false,
        { type: 'modifierSurvey/setDietKind', dietKind }
      ),
    setDietKindText: (dietKindText: string | undefined) =>
      set(
        (prev: ModifierSurveyStore) => ({
          modifierResults: { ...prev.modifierResults, dietKindText },
        }),
        false,
        { type: 'modifierSurvey/setDietKindText', dietKindText }
      ),
    setOrganicProducts: (organicProducts: OrganicProducts) =>
      set(
        (prev: ModifierSurveyStore) => ({
          modifierResults: {
            ...prev.modifierResults,
            organicProducts,
          },
        }),
        false,
        {
          type: 'modifierSurvey/setOrganicProducts',
          organicProducts,
        }
      ),
    setOrganicProductsText: (organicProductsText: string | undefined) =>
      set(
        (prev: ModifierSurveyStore) => ({
          modifierResults: {
            ...prev.modifierResults,
            organicProductsText,
          },
        }),
        false,
        {
          type: 'modifierSurvey/setOrganicProductsText',
          organicProductsText,
        }
      ),
    setOfficeImprovement: (officeImprovement: OfficeImprovement) =>
      set(
        (prev: ModifierSurveyStore) => ({
          modifierResults: {
            ...prev.modifierResults,
            officeImprovement,
          },
        }),
        false,
        {
          type: 'modifierSurvey/setOfficeImprovement',
          officeImprovement,
        }
      ),
    setOfficeImprovementText: (officeImprovementText: string | undefined) =>
      set(
        (prev: ModifierSurveyStore) => ({
          modifierResults: {
            ...prev.modifierResults,
            officeImprovementText,
          },
        }),
        false,
        {
          type: 'modifierSurvey/setOfficeImprovementText',
          officeImprovementText,
        }
      ),
    setElectronics: (electronics: Electronics) =>
      set(
        (prev: ModifierSurveyStore) => ({
          modifierResults: {
            ...prev.modifierResults,
            electronics,
          },
        }),
        false,
        { type: 'modifierSurvey/setElectronics', electronics }
      ),
    setElectronicsText: (electronicsText: string | undefined) =>
      set(
        (prev: ModifierSurveyStore) => ({
          modifierResults: {
            ...prev.modifierResults,
            electronicsText,
          },
        }),
        false,
        { type: 'modifierSurvey/setElectronicsText', electronicsText }
      ),
    setHotels: (hotels: Hotels) =>
      set(
        (prev: ModifierSurveyStore) => ({
          modifierResults: { ...prev.modifierResults, hotels },
        }),
        false,
        { type: 'modifierSurvey/setHotels', hotels }
      ),
    setHotelsText: (hotelsText: string | undefined) =>
      set(
        (prev: ModifierSurveyStore) => ({
          modifierResults: { ...prev.modifierResults, hotelsText },
        }),
        false,
        { type: 'modifierSurvey/setHotelsText', hotelsText }
      ),
    setRevenue: (revenue: number) =>
      set(
        (prev: ModifierSurveyStore) => ({
          modifierResults: { ...prev.modifierResults, revenue },
        }),
        false,
        {
          type: 'modifierSurvey/setRevenue',
          revenue,
        }
      ),
    setEmployeeCount: (employeeCount: number) =>
      set(
        (prev: ModifierSurveyStore) => ({
          modifierResults: { ...prev.modifierResults, employeeCount },
        }),
        false,
        {
          type: 'modifierSurvey/setEmployeeCount',
          employeeCount,
        }
      ),
    setElectricityShare: (electricityShare: number) =>
      set(
        (prev: ModifierSurveyStore) => ({
          modifierResults: { ...prev.modifierResults, electricityShare },
        }),
        false,
        {
          type: 'modifierSurvey/setElectricityShare',
          electricityShare,
        }
      ),
    setHeatingShare: (heatingShare: number) =>
      set(
        (prev: ModifierSurveyStore) => ({
          modifierResults: { ...prev.modifierResults, heatingShare },
        }),
        false,
        {
          type: 'modifierSurvey/setHeatingShare',
          heatingShare,
        }
      ),
    setHeatingKwh: (heatingKwh: number) =>
      set(
        (prev: ModifierSurveyStore) => ({
          modifierResults: { ...prev.modifierResults, heatingKwh },
        }),
        false,
        {
          type: 'modifierSurvey/setHeatingKwh',
          heatingKwh,
        }
      ),
    setElectricityKwh: (electricityKwh: number) =>
      set(
        (prev: ModifierSurveyStore) => ({
          modifierResults: { ...prev.modifierResults, electricityKwh },
        }),
        false,
        {
          type: 'modifierSurvey/setElectricityKwh',
          electricityKwh,
        }
      ),
    setOfficeSizeQm: (officeSizeQm: number) =>
      set(
        (prev: ModifierSurveyStore) => ({
          modifierResults: { ...prev.modifierResults, officeSizeQm },
        }),
        false,
        {
          type: 'modifierSurvey/setOfficeSizeQm',
          officeSizeQm,
        }
      ),
    setElectricityHeatingShareText: (electricityHeatingShareText: string) =>
      set(
        (prev: ModifierSurveyStore) => ({
          modifierResults: {
            ...prev.modifierResults,
            electricityHeatingShareText,
          },
        }),
        false,
        {
          type: 'modifierSurvey/setOfficeSizeQm',
          electricityHeatingShareText,
        }
      ),
    resetWarmRent: () =>
      set(
        (prev: ModifierSurveyStore) => ({
          modifierResults: {
            ...prev.modifierResults,
            electricityShare: undefined,
            heatingShare: undefined,
          },
        }),
        false,
        { type: 'modifierSurvey/resetRent' }
      ),
    resetExactUsage: () =>
      set(
        (prev: ModifierSurveyStore) => ({
          modifierResults: {
            ...prev.modifierResults,
            electricityKwh: undefined,
            heatingKwh: undefined,
          },
        }),
        false,
        { type: 'modifierSurvey/resetRent' }
      ),
    resetGuess: () =>
      set(
        (prev: ModifierSurveyStore) => ({
          modifierResults: {
            ...prev.modifierResults,
            officeSizeQm: undefined,
            electricityHeatingShareText: undefined,
          },
        }),
        false,
        { type: 'modifierSurvey/resetRent' }
      ),
    setOtherText: (otherText: string) =>
      set(
        (prev: ModifierSurveyStore) => ({
          modifierResults: { ...prev.modifierResults, otherText },
        }),
        false,
        { type: 'modifierSurvey/setOtherText', otherText }
      ),
    setModifierSurveyResults: (modifierSurveyResults: ModifierSurveyResults) =>
      set(
        () => ({
          modifierResults: modifierSurveyResults,
        }),
        false,
        {
          type: 'modifierSurvey/setModifierSurveyResults',
          modifierSurveyResults,
        }
      ),
    resetModifierSurveyResults: () =>
      set(
        (prev: ModifierSurveyStore) => ({
          modifierResults: {
            ...prev.modifierResults,
            employeeCount: undefined,
            revenue: undefined,
            gasAndFuel: undefined,
            gasAndFuelText: '',
            heatingKind: undefined,
            heatingKindText: '',
            renewableEnergyShare: undefined,
            renewableEnergyShareText: '',
            heatingShare: undefined,
            electricityShare: undefined,
            heatingKwh: undefined,
            electricityKwh: undefined,
            officeSizeQm: undefined,
            electricityHeatingShareText: '',
            dietKind: undefined,
            dietKindText: '',
            electronics: undefined,
            electronicsText: '',
            hotels: undefined,
            hotelsText: '',
            officeImprovement: undefined,
            officeImprovementText: '',
            organicProducts: undefined,
            organicProductsText: '',
            otherText: '',
          },
        }),
        false,
        {
          type: 'modifierSurvey/resetModifierSurveyResults',
        }
      ),
  })) as any
)
