import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useModifierSurveyContext } from '../../pages/Modifier'
import { useModifierSurveyStore } from '../../store/useModifierSurveryStore'
import { Hotels } from '../../utils/enums/modifierSurveyEnums'
import { Button } from '../core/Button'
import { QuestionWidget } from './QuestionWidget'

export interface SurveyQuestionHotelsProps {
  editable: boolean
}

export const SurveyQuestionHotels = ({
  editable,
}: SurveyQuestionHotelsProps) => {
  const context = useModifierSurveyContext()
  const { t } = useTranslation(['modifierSurvey'])
  const options = Object.keys(Hotels).map((value: string) => ({
    name: t(`modifierSurvey:hotels.${value}`),
    value,
  }))
  const [selectedValue, setSelectedValue] = useState<Hotels | undefined>(
    undefined
  )

  const hotels = useModifierSurveyStore().modifierResults?.hotels
  const hotelsText = useModifierSurveyStore().modifierResults?.hotelsText
  const setHotels = useModifierSurveyStore().setHotels
  const setHotelsText = useModifierSurveyStore().setHotelsText

  useEffect(() => {
    setSelectedValue(hotels)
  }, [hotels])

  const onSelect = (selectedValue: string) => {
    setHotels(selectedValue as Hotels)
  }

  const onCommentInput = (comment: string) => {
    setHotelsText(comment)
  }

  return (
    <>
      <div
        className={classNames(editable ? 'pt-8 w-full px-8 py-16' : 'w-full')}
      >
        <QuestionWidget
          options={options}
          selectedValue={selectedValue}
          onSelect={onSelect}
          onCommentInput={onCommentInput}
          note={hotelsText}
          editable={editable}
        />
      </div>
      {editable && (
        <div className="bg-gray-100 py-4 flex items-center justify-between w-full px-8">
          <Button
            onClick={() => context?.onPrevTab()}
            label={t('modifierSurvey:common.backBtn')}
            variant="secondary"
          />
          <Button
            onClick={() => context?.onNextTab()}
            label={t('modifierSurvey:common.nextBtn')}
            variant="primary"
            disabled={selectedValue === undefined}
          />
        </div>
      )}
    </>
  )
}
