import { AgGridReact } from 'ag-grid-react'
import { useEffect, useState } from 'react'
import { getCustomers } from '../../api/customer/getCustomers'
import { updateFootprint } from '../../api/customer/updateFootprint'
import { Footprint } from '../../models/footprint'
import { useCustomerStore } from '../../store/useCustomerStore'
import { Button } from '../core/Button'
import { JSONUploadComponent } from './JSONUpload'

export const FakeCustomerLogin = () => {
  const [rowData, setRowData] = useState<any[]>()

  const setFakeCustomer = useCustomerStore((state) => state.setFakeCustomer)
  const customer = useCustomerStore((state) => state.customer)
  const originalId = useCustomerStore((state) => state.customerId)

  const columnDefs = [
    {
      field: 'companyName',
      headerName: 'Unternehmensname',
      filter: 'agTextColumnFilter',
      sort: 'desc' as any,
      flex: 0.5,
      cellRenderer: (params: any) => {
        return (
          <div className="py-1">
            <span>{params.value}</span>
          </div>
        )
      },
    },
    {
      field: '',
      headerName: 'Daten überschreiben',
      flex: 0.25,
      cellRenderer: (params: any) => {
        return (
          <div className="py-1">
            <JSONUploadComponent
              uploadData={(footprint: Footprint) =>
                updateFootprint(params.data.id, footprint)
              }
              onUploadSuccess={() => {
                return
              }}
            />
          </div>
        )
      },
    },
    {
      field: '',
      headerName: 'Log in',
      flex: 0.25,
      cellRenderer: (params: any) => {
        if (params.data.id === originalId) {
          return <div></div>
        }

        if (customer?.id === params.data.id) {
          return (
            <div className="py-1">
              <a href="0" className="underline">
                Login zurücksetzen
              </a>
            </div>
          )
        }

        return (
          <div className="py-1">
            <Button
              variant="secondary"
              label="Log in übernehmen"
              onClick={() => fakeCustomer(params)}
            />
          </div>
        )
      },
    },
  ]

  const fakeCustomer = (params: any) => {
    setFakeCustomer(params.data)
  }

  useEffect(() => {
    const fetchCustomer = async () => {
      const customers = await getCustomers()

      setRowData(customers.data)
    }

    fetchCustomer()
  }, [originalId])

  return (
    <div
      className="ag-theme-alpine mt-4 bg-white shadow-sm rounded-md py-4 px-4 h-full"
      style={{ width: '100%', height: '100%' }}
    >
      {rowData?.length && rowData.length > 0 ? (
        <AgGridReact
          rowData={rowData}
          rowHeight={50}
          columnDefs={columnDefs}
          animateRows={true}
          onGridReady={(e) => {
            e.api.sizeColumnsToFit({ defaultMinWidth: 200 })
          }}
        />
      ) : (
        <div>Kunden konnten nicht geladen werden</div>
      )}
    </div>
  )
}
