import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LogarithmicScale,
  Tooltip,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'
import { Footprint } from '../../models/footprint'
import { formatNumber } from '../../utils/formatNumber'

ChartJS.register(CategoryScale, LogarithmicScale, BarElement, Tooltip)
export const options = {
  indexAxis: 'y' as const,
  elements: {
    bar: {
      borderWidth: 1,
    },
  },
  scales: {
    y: {
      ticks: {
        mirror: true,
        color: 'black',
        font: {
          size: 14,
          family: 'sans-serif',
        },
      },
      grid: {
        display: false,
      },
    },
    x: {
      // type: 'logarithmic' as any,
      display: false,
      ticks: {
        display: false,
      },
      grid: {
        display: false,
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  barThickness: 30,
}

export interface FootprintChartProps {
  footprint: Footprint
}

export const FootprintChart = ({ footprint }: FootprintChartProps) => {
  const { t } = useTranslation(['translation', 'modifierSurvey'])
  const { total: _, ...rest } = footprint

  const tooltip = `${t('dashboard.charts.categoryTooltip1')}

  ${t('dashboard.charts.categoryTooltip2')}
  `

  const chartData = {
    fuels: Number.parseInt(rest.fuels) / 1000,
    mobility: Number.parseInt(rest.mobility) / 1000,
    heating: Number.parseInt(rest.heating) / 1000,
    electricity: Number.parseInt(rest.electricity) / 1000,
    rent: Number.parseInt(rest.rent) / 1000,
    food: Number.parseInt(rest.food) / 1000,
    electronics: Number.parseInt(rest.electronics) / 1000,
    itServices: Number.parseInt(rest.itServices) / 1000,
    hotels: Number.parseInt(rest.hotels) / 1000,
    office: Number.parseInt(rest.office) / 1000,
    teamEvents: Number.parseInt(rest.teamEvents) / 1000,
    marketing: Number.parseInt(rest.marketing) / 1000,
    misc: Number.parseInt(rest.misc) / 1000,
    climateCompensation:
      Math.abs(Number.parseInt(rest.climateCompensation)) / 1000,
  }

  const data = {
    labels: [
      `${t('modifierSurvey:chart.fuels')}: ${formatNumber(chartData.fuels)} t`,
      `${t('modifierSurvey:chart.mobility')}: ${formatNumber(
        chartData.mobility
      )} t`,
      `${t('modifierSurvey:chart.heating')}: ${formatNumber(
        chartData.heating
      )} t`,
      `${t('modifierSurvey:chart.electricity')}: ${formatNumber(
        chartData.electricity
      )} t`,
      `${t('modifierSurvey:chart.rent')}: ${formatNumber(chartData.rent)} t`,
      `${t('modifierSurvey:chart.food')}: ${formatNumber(chartData.food)} t`,
      `${t('modifierSurvey:chart.electronics')}: ${formatNumber(
        chartData.electronics
      )} t`,
      `${t('modifierSurvey:chart.itServices')}: ${formatNumber(
        chartData.itServices
      )} t`,
      `${t('modifierSurvey:chart.hotels')}: ${formatNumber(
        chartData.hotels
      )} t`,
      `${t('modifierSurvey:chart.office')}: ${formatNumber(
        chartData.office
      )} t`,
      `${t('modifierSurvey:chart.teamEvents')}: ${formatNumber(
        chartData.teamEvents
      )} t`,
      `${t('modifierSurvey:chart.marketing')}: ${formatNumber(
        chartData.marketing
      )} t`,
      `${t('modifierSurvey:chart.misc')}: ${formatNumber(chartData.misc)} t`,
      `${t('modifierSurvey:chart.compensation')}: - ${formatNumber(
        chartData.climateCompensation
      )} t`,
    ],
    datasets: [
      {
        data: [
          chartData.fuels,
          chartData.mobility,
          chartData.heating,
          chartData.electricity,
          chartData.rent,
          chartData.food,
          chartData.electronics,
          chartData.itServices,
          chartData.hotels,
          chartData.office,
          chartData.teamEvents,
          chartData.marketing,
          chartData.misc,
          chartData.climateCompensation,
        ],
        backgroundColor: [
          'rgba(59, 130, 246, 0.5)',
          'rgba(59, 130, 246, 0.5)',
          'rgba(59, 130, 246, 0.5)',
          'rgba(59, 130, 246, 0.5)',
          'rgba(59, 130, 246, 0.5)',
          'rgba(59, 130, 246, 0.5)',
          'rgba(59, 130, 246, 0.5)',
          'rgba(59, 130, 246, 0.5)',
          'rgba(59, 130, 246, 0.5)',
          'rgba(59, 130, 246, 0.5)',
          'rgba(59, 130, 246, 0.5)',
          'rgba(59, 130, 246, 0.5)',
          'rgba(59, 130, 246, 0.5)',
          'rgba(135, 192, 76, 0.5)',
        ],
        borderRadius: 8,
      },
    ],
  }

  return (
    <>
      <ReactTooltip
        anchorId="category-chart-hint"
        place="bottom"
        className="w-60 whitespace-pre-line"
      />
      <dt className="truncate text-sm font-medium text-gray-500 flex items-center">
        {t('dashboard.charts.footprintByCategory')}
        <div
          className="w-4 h-4 ml-2 text-gray-500"
          data-tooltip-content={tooltip}
          id="category-chart-hint"
        >
          <QuestionMarkCircleIcon />
        </div>
      </dt>
      <dd className="mt-4 text-3xl font-semibold tracking-tight text-gray-900">
        <div className="min-h-[30rem]">
          <Bar options={options} data={data} />
        </div>
      </dd>
    </>
  )
}
