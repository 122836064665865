export enum Industry {
  RETAIL = 'RETAIL',
  VEHICLE_MAINTENANCE = 'VEHICLE_MAINTENANCE',
  TRANSPORT_AND_STORAGE = 'TRANSPORT_AND_STORAGE',
  HOSPITALITY = 'HOSPITALITY',
  FILM_AND_MEDIA_PRODUCTION = 'FILM_AND_MEDIA_PRODUCTION',
  IT_SERVICES = 'IT_SERVICES',
  OTHER_IT_SERVICES = 'OTHER_IT_SERVICES',
  FINANCE_AND_INSURANCE = 'FINANCE_AND_INSURANCE',
  REAL_ESTATE = 'REAL_ESTATE',
  PROFESSIONAL_SERVICES = 'PROFESSIONAL_SERVICES',
  EVENT_ORGANIZER = 'PROFESSIONAL_SERVICES',
  LANDSCAPING = 'LANDSCAPING',
  ART_SPORT_ENTERTAINMENT = 'ART_SPORT_ENTERTAINMENT',
  REPAIR_SERVICES = 'REPAIR_SERVICES',
  OTHER_SERVICES = 'OTHER_SERVICES',
  ENERGY_SUPPLY = 'ENERGY_SUPPLY',
  OTHER = 'OTHER',
}
