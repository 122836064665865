import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useModifierSurveyContext } from '../../pages/Modifier'
import { useModifierSurveyStore } from '../../store/useModifierSurveryStore'
import { GasAndFuel } from '../../utils/enums/modifierSurveyEnums'
import { Button } from '../core/Button'
import { QuestionWidget } from './QuestionWidget'

export interface SurveyQuestionFuelProps {
  editable: boolean
}

export const SurveyQuestionFuel = ({ editable }: SurveyQuestionFuelProps) => {
  const context = useModifierSurveyContext()
  const { t } = useTranslation(['modifierSurvey'])
  const options = Object.keys(GasAndFuel).map((value: string) => ({
    name: t(`modifierSurvey:fuel.${value}`),
    value,
  }))
  const [selectedValue, setSelectedValue] = useState<GasAndFuel | undefined>(
    undefined
  )

  const gasAndFuel = useModifierSurveyStore().modifierResults?.gasAndFuel
  const gasAndFuelText =
    useModifierSurveyStore().modifierResults?.gasAndFuelText
  const setGasAndFuel = useModifierSurveyStore().setGasAndFuel
  const setGasAndFuelText = useModifierSurveyStore().setGasAndFuelText

  useEffect(() => {
    setSelectedValue(gasAndFuel)
  }, [gasAndFuel])

  const onSelect = (selectedValue: string) => {
    setGasAndFuel(selectedValue as GasAndFuel)
  }

  const onCommentInput = (comment: string) => {
    setGasAndFuelText(comment)
  }

  return (
    <>
      <div
        className={classNames(editable ? 'pt-8 w-full px-8 py-16' : 'w-full')}
      >
        <QuestionWidget
          options={options}
          selectedValue={selectedValue}
          onSelect={onSelect}
          onCommentInput={onCommentInput}
          note={gasAndFuelText}
          editable={editable}
        />
      </div>
      {editable && (
        <div className="bg-gray-100 py-4 flex items-center justify-between w-full px-8">
          <Button
            onClick={() => context?.onPrevTab()}
            label={t('modifierSurvey:common.backBtn')}
            variant="secondary"
          />
          <Button
            onClick={() => context?.onNextTab()}
            label={t('modifierSurvey:common.nextBtn')}
            variant="primary"
            disabled={selectedValue === undefined}
          />
        </div>
      )}
    </>
  )
}
